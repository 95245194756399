import { Outlet } from 'react-router-dom';
import { Footer } from './Footer';

export function Layout() {
  return (
    <div className="h-screen w-full bg-surface-dark xl:flex xl:justify-center xl:pt-16">
      <div className="h-full bg-surface-bright xl:h-[900px] xl:w-[1024px]">
        <div className="flex h-full flex-col justify-between">
          <Outlet />

          <Footer />
        </div>
      </div>
    </div>
  );
}
