import barcodeIcon from '@assets/icons/icon-barcode.svg';
import { zodResolver } from '@hookform/resolvers/zod';
import { useApi } from '@hooks/useApi';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@ui/Button';
import { Input } from '@ui/Input';
import { Text } from '@ui/Text';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  packingNumber: z.string().min(9, 'Ungültiger Container Barcode'),
});

type Props = {
  setContainerId: (id: string) => void;
  isOpen: boolean;
  onOpen: () => void;
};

export function ContainerPackingNumberCard({ setContainerId, isOpen, onOpen }: Props) {
  const api = useApi();

  const form = useForm<z.infer<typeof formSchema>>({ resolver: zodResolver(formSchema) });

  const createContainerMutation = useMutation({
    mutationFn: api.containers.createContainer,
  });

  const getAllContainersMutation = useMutation({
    mutationFn: api.containers.getAllContainers,
  });

  const handleSubmit = form.handleSubmit((data) => {
    getAllContainersMutation.mutate(undefined, {
      onSuccess: (res) => {
        const serial = data.packingNumber.slice(8);
        const container = res.data.data.find((c) => c.serial === serial);

        if (!container) {
          createContainerMutation.mutate(
            { data: { serial: serial, type: 'ibc' } },
            {
              onSuccess: (res) => {
                if (!res.ok) {
                  switch (res.data.errCode) {
                    case 'serial_is_already_in_use': {
                      // This case should never trigger because we *just* tried to get a container by serial and didn't
                      // find one.
                      return;
                    }
                    default: {
                      const _exhaustiveCheck: never = res.data.errCode;
                      return;
                    }
                  }
                }

                setContainerId(res.data.id);
              },
            }
          );

          return;
        }

        form.reset();
        setContainerId(container.id);
      },
    });
  });

  return (
    <div className="rounded bg-white shadow hover:shadow-lg">
      <form onSubmit={handleSubmit}>
        <button type="button" onClick={onOpen} className="flex w-full items-center gap-7 px-5 py-4" disabled={isOpen}>
          <img src={barcodeIcon} alt="" />
          <h2>
            <Text variant="heading2">Container Barcode</Text>
          </h2>
        </button>

        {isOpen && (
          <div className="flex flex-col gap-6 px-5 py-4">
            <Input
              type="text"
              label="Container Barcode"
              required
              errorMessage={form.formState.errors.packingNumber?.message}
              autoFocus
              {...form.register('packingNumber')}
            />

            <div className="grid grid-cols-2">
              <Button
                fullWidth
                disabled={getAllContainersMutation.isLoading || createContainerMutation.isLoading}
                className="col-start-2"
              >
                Scan
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
