import * as zustand from 'zustand';

type Header = {
  title: string;
  actionElement: React.ReactNode | null;
};

type HeaderState = {
  header: Header | null;
  setHeader: (header: Partial<Header>) => void;
};

const defaultHeader: Header = {
  title: '',
  actionElement: null,
};

export const useDesktopHeaderStore = zustand.create<HeaderState>((set) => ({
  header: null,
  setHeader: (header: Partial<Header>) => {
    set({ header: { ...defaultHeader, ...header } });
  },
}));
