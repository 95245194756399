import { zodResolver } from '@hookform/resolvers/zod';
import { useApi } from '@hooks/useApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { notify } from '../../../notify';
import { DataWrapper } from '@ui/DataWrapper';
import { Text } from '@ui/Text';
import { InputDropdown } from '@ui/InputDropdown';
import { Input } from '@ui/Input';
import { Button } from '@ui/Button';

const formSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('ibc'),
    lifetimeExpiresAt: z.string().min(1, 'Kein Datum ausgewählt.').pipe(z.coerce.date()),
    chemicalId: z.string().uuid('Keine Chemie ausgewählt.'),
  }),
  z.object({
    type: z.literal('tank_container'),
    lifetimeExpiresAt: z.string().min(1, 'Kein Datum ausgewählt.').pipe(z.coerce.date()),
    chemicalId: z.string().uuid('Keine Chemie ausgewählt.'),
    nextInspectionType: z.enum(['major', 'minor'], { errorMap: () => ({ message: 'Kein Prüftyp ausgewählt.' }) }),
  }),
]);

type Props = {
  container: {
    id: string;
    serial: string;
    type: 'ibc' | 'tank_container';
    lifetimeExpiresAt: Date | null;
    nextInspectionType: 'major' | 'minor' | null;
    chemical: {
      id: string;
    } | null;
  };
  onCancel: () => void;
};

export function GeneralTab({ container, onCancel }: Props) {
  const api = useApi();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: container.type,
    },
  });
  const formTypeWatch = form.watch('type');

  const containerMutation = useMutation({
    mutationFn: api.containers.updateContainer,
  });

  const chemicalsQuery = useQuery({
    queryKey: ['chemicals'],
    queryFn: api.chemicals.getAllChemicals,
  });

  const handleSubmit = form.handleSubmit((data) => {
    containerMutation.mutate(
      {
        containerId: container.id,
        data: {
          type: data.type,
          chemicalId: data.chemicalId,
          lifetimeExpiresAt: data.lifetimeExpiresAt,
          nextInspectionType: data.type === 'tank_container' ? data.nextInspectionType : null,
        },
      },
      {
        onSuccess: async (res) => {
          if (!res.ok) {
            switch (res.data.errCode) {
              case 'container_not_found': {
                queryClient.invalidateQueries(['containers']);
                return;
              }
              case 'chemical_not_found': {
                queryClient.invalidateQueries(['containers']);
                return;
              }
              default: {
                const _exhaustiveCheck: never = res.data.errCode;
                return;
              }
            }
          }

          await queryClient.invalidateQueries(['containers']);
          notify({ type: 'success', message: 'Änderungen gespeichert.' });
        },
      }
    );
  });

  const chemicals = chemicalsQuery.data?.data.data || [];

  if (chemicalsQuery.isLoading) return null;

  return (
    <div className="px-6">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-[10px]">
          <DataWrapper label="Container S/N" value={container.serial} />
          <div>
            <Text variant="body2">Typ:</Text>

            <InputDropdown
              label="Typ"
              options={[
                {
                  key: 'IBC',
                  value: 'ibc',
                },
                {
                  key: 'Tank Container',
                  value: 'tank_container',
                },
              ]}
              defaultValue={container.type}
              variant={'contained'}
              errorMessage={form.formState.errors.type?.message}
              {...form.register('type')}
            />
          </div>

          {formTypeWatch === 'tank_container' && (
            <div>
              <div>
                <Text variant="body2">Prüftyp:</Text>

                <InputDropdown
                  label="Prüftyp"
                  options={[
                    {
                      key: '-',
                      value: '-',
                      selectable: false,
                    },
                    {
                      key: 'Kleiner TÜV',
                      value: 'minor',
                    },
                    {
                      key: 'Großer TÜV',
                      value: 'major',
                    },
                  ]}
                  defaultValue={container.nextInspectionType || '-'}
                  variant={'contained'}
                  // @ts-ignore
                  errorMessage={form.formState.errors.nextInspectionType?.message}
                  {...form.register('nextInspectionType')}
                />
              </div>
            </div>
          )}

          {formTypeWatch === 'ibc' ? (
            <div>
              <Text variant="body2">Lebenszeit:</Text>

              <Input
                variant="contained"
                type="date"
                label="Lebenszeit"
                defaultValue={container.lifetimeExpiresAt?.toISOString().split('T')[0]}
                errorMessage={form.formState.errors.lifetimeExpiresAt?.message}
                {...form.register('lifetimeExpiresAt')}
              />
            </div>
          ) : (
            <div>
              <Text variant="body2">Prüfdatum:</Text>

              <Input
                variant="contained"
                type="date"
                label="Prüfdatum"
                defaultValue={container.lifetimeExpiresAt?.toISOString().split('T')[0]}
                errorMessage={form.formState.errors.lifetimeExpiresAt?.message}
                {...form.register('lifetimeExpiresAt')}
              />
            </div>
          )}
          <div>
            <Text variant="body2">Chemie Name:</Text>

            <InputDropdown
              label="Chemie Name"
              options={[
                {
                  key: '-',
                  value: '-',
                  selectable: false,
                },
                ...chemicals.map((chemical) => ({
                  key: chemical.name,
                  value: chemical.id,
                })),
              ]}
              defaultValue={container.chemical?.id || '-'}
              variant={'contained'}
              errorMessage={form.formState.errors.chemicalId?.message}
              {...form.register('chemicalId')}
            />
          </div>
        </div>
        <div className="mb-16 mt-10 block justify-end gap-4 min-[350px]:flex">
          <Button
            type="button"
            variant="outlined"
            onClick={onCancel}
            className="w-[160px] max-[500px]:w-full max-[350px]:mb-3"
          >
            Abbrechen
          </Button>
          <Button type="submit" variant="contained" className="w-[160px] max-[500px]:w-full">
            Speichern
          </Button>
        </div>
      </form>
    </div>
  );
}
