import { z } from 'zod';

const ConfigSchema = z.object({
  NODE_ENV: z.enum(['development', 'production']).default('production'),
  API_URI: z.string(),
  MAPBOX_ACCESS_TOKEN: z.string(),
});

const CONFIG = ConfigSchema.parse({
  NODE_ENV: import.meta.env.MODE,
  API_URI: import.meta.env.VITE_API_URI,
  MAPBOX_ACCESS_TOKEN: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
});

export default CONFIG;
