import { cn } from '../../utils';
import { Text } from '@ui/Text';

type TableProps = {} & React.ComponentProps<'table'>;

export function Table({ children, className, ...rest }: TableProps) {
  return (
    <table className={cn('w-full table-auto whitespace-nowrap text-sm', className)} {...rest}>
      {children}
    </table>
  );
}

type HeaderProps = {} & React.ComponentProps<'thead'>;

function Header({ children, className, ...rest }: HeaderProps) {
  return (
    <thead
      className={cn('sticky top-0 border-b border-gray-50 bg-white shadow-table-border-hack', className)}
      {...rest}
    >
      {children}
    </thead>
  );
}

type HeadProps = {} & React.ComponentProps<'th'>;

function Head({ children, className, ...rest }: HeadProps) {
  return (
    <th className={cn('px-2 py-6 text-left first:pl-6 last:pr-6', className)} {...rest}>
      <Text variant="heading3">{children}</Text>
    </th>
  );
}

type BodyProps = {} & React.ComponentProps<'tbody'>;

function Body({ children, className, ...rest }: BodyProps) {
  return (
    <tbody className={cn('divide-y divide-gray-100 border-b border-gray-100', className)} {...rest}>
      {children}
    </tbody>
  );
}

type RowProps = {
  selected?: boolean;
  noHover?: boolean;
} & React.ComponentProps<'tr'>;

function Row({ children, className, selected = false, noHover = false, ...rest }: RowProps) {
  return (
    <tr className={cn(!noHover && 'hover:bg-surface-bright', selected && 'bg-surface-dark', className)} {...rest}>
      {children}
    </tr>
  );
}

type CellProps = {} & React.ComponentProps<'td'>;

function Cell({ children, className, ...rest }: CellProps) {
  return (
    <td className={cn('px-2 py-0.5 first:pl-6 last:pr-6', className)} {...rest}>
      {children || <Text>-</Text>}
    </td>
  );
}

Table.Header = Header;
Table.Head = Head;
Table.Body = Body;
Table.Row = Row;
Table.Cell = Cell;
