import React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../utils';
import ErrorIcon from '@mui/icons-material/Error';

const InputWrapperStyles = cva('w-full flex items-center focus:ring-0 ring-0', {
  variants: {
    variant: {
      outlined:
        'bg-transparent outline outline-offset-0 rounded-sm outline-1 outline-gray-600 hover:outline-gray-700 hover:outline-[1.5px] focus-within:hover:outline-2 focus-within:hover:outline-blue-600 focus-within:outline-2 focus-within:outline-blue-600 border-none',
      contained:
        'bg-gray-50 rounded-t-sm border-b-[1px] hover:border-b-2 hover:-mb-[1.2px] focus-within:border-b-2 focus-within:-mb-[1.5px] border-gray-700 focus-within:hover:border-blue-600 focus-within:border-blue-600',
      search:
        'border-b-[1px] border-gray-100 hover:-mb-[1.3px] hover:border-b-[2px] focus-within:-mb-[1.3px] focus-within:border-b-[2px] focus-within:hover:border-communication-add focus-within:border-communication-add',
    },
  },
  defaultVariants: {
    variant: 'outlined',
  },
});

const InputWrapperStylesError = cva('ring-0', {
  variants: {
    variant: {
      outlined:
        'outline-communication-error hover:outline-communication-errorDark focus:outline-communication-error focus-within:hover:outline-communication-error focus-within:outline-communication-error',
      contained:
        'border-communication-error focus-within:border-communication-error focus-within:hover:border-communication-error',
      search: '',
    },
  },
  defaultVariants: {
    variant: 'outlined',
  },
});

const InputStyles = cva(
  'w-full rounded-sm border-none bg-transparent px-4 pt-3.5 caret-blue-600 outline-none focus:border-none focus:outline-none focus:ring-0',
  {
    variants: {
      variant: {
        outlined: 'py-3.5',
        contained: 'pb-[13px] pt-3.5',
        search: 'py-3.5',
      },
    },
    defaultVariants: {
      variant: 'outlined',
    },
  }
);

const InputWrapperStylesDisabled = cva('', {
  variants: {
    variant: {
      outlined: ' outline-gray-200 hover:outline-1 hover:outline-gray-200 text-onSurface-lowEmphasis',
      contained: 'hover:border-b-[1px] hover:-mb-0 text-onSurface-lowEmphasis border-gray-300 bg-surface-dark',
      search: '',
    },
  },
  defaultVariants: {
    variant: 'outlined',
  },
});

type Props = {
  label: string;
  disabled?: boolean;
  LeadingIcon?: React.ReactNode;
  TrailingIcon?: React.ReactNode;
  errorMessage?: string;
  helperText?: string;
} & React.ComponentProps<'input'> &
  VariantProps<typeof InputStyles>;

export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { variant, disabled, LeadingIcon, TrailingIcon, label, helperText, errorMessage, className, ...rest } = props;

  return (
    <div>
      <div
        className={cn(
          InputWrapperStyles({ variant }),
          disabled && cn(InputWrapperStylesDisabled({ variant })),
          errorMessage && cn(InputWrapperStylesError({ variant }))
        )}
      >
        {LeadingIcon ? <div className="mx-3">{LeadingIcon}</div> : <></>}
        <input
          ref={ref}
          placeholder={label}
          {...rest}
          disabled={disabled}
          className={cn(InputStyles({ variant }), errorMessage && 'caret-communication-error', className)}
        />
        {errorMessage ? <ErrorIcon className="mx-3 text-communication-error" /> : <></>}
        {TrailingIcon ? <div className="mx-3">{TrailingIcon}</div> : <></>}
      </div>

      {errorMessage ? (
        <p className=" mt-2 px-4 text-xs text-communication-error">{errorMessage}</p>
      ) : (
        helperText && (
          <p
            className={cn(
              'mt-2 px-4 text-xs ',
              disabled ? 'text-onSurface-lowEmphasis' : 'text-onSurface-mediumEmphasis'
            )}
          >
            {helperText}
          </p>
        )
      )}
    </div>
  );
});
