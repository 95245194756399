import { Layer, Map, MapRef, Marker, Source } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { useMapStyleStore } from '@stores/mapStyleStore';
import CONFIG from '../../../../../config';
import { MarkerPin } from '@ui/MarkerPin';
import { bbox } from '@turf/turf';
import { Button } from '@ui/Button';
import { cn } from '../../../../../utils';

type Props = {
  container: {
    type: 'ibc' | 'tank_container';
  };
  historyEntries: {
    id: string;
    location: {
      lng: number;
      lat: number;
    } | null;
    recordedAt: Date;
  }[];
  selectedHistoryEntryId: string | undefined;
  hoveredHistoryEntryId: string | undefined;
  filterFrom: string;
  filterTo: string;
  className?: string;
};

export function ContainerDetailsMap({
  container,
  historyEntries,
  selectedHistoryEntryId,
  hoveredHistoryEntryId,
  filterFrom,
  filterTo,
  className,
}: Props) {
  const mapStyleStore = useMapStyleStore();

  const mapRef = useRef<MapRef>(null);

  const [isInitialized, setIsInitialized] = useState(false);
  const [viewState, setViewState] = useState({
    longitude: historyEntries[0]?.location?.lng || 0,
    latitude: historyEntries[0]?.location?.lat || 0,
    zoom: 15,
  });

  const historyLineGeoJson = {
    type: 'Feature' as const,
    properties: {},
    geometry: {
      coordinates: historyEntries.filter((x) => x.location !== null).map((x) => [x.location!.lng, x.location!.lat]),
      type: 'LineString' as const,
    },
  };

  const selectedHistoryEntry = historyEntries.find((x) => x.id === selectedHistoryEntryId);
  const hoveredHistoryEntry = historyEntries.find((x) => x.id === hoveredHistoryEntryId);

  useEffect(() => {
    if (!mapRef.current) return;
    if (!isInitialized) return;
    if (historyLineGeoJson.geometry.coordinates.length === 0) return;

    const [minLng, minLat, maxLng, maxLat] = bbox(historyLineGeoJson);

    // TODO determine padding dynamically based on map size | 08.02.2024 jl
    mapRef.current.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { duration: 2000, padding: 50, essential: true, maxZoom: 15 }
    );
  }, [isInitialized, filterFrom, filterTo]);

  useEffect(() => {
    if (!mapRef.current) return;
    if (!isInitialized) return;
    if (!selectedHistoryEntry) return;
    if (!selectedHistoryEntry.location) return;

    mapRef.current.flyTo({
      center: [selectedHistoryEntry.location.lng, selectedHistoryEntry.location.lat],
      zoom: 15,
      duration: 2000,
      essential: true,
    });
  }, [selectedHistoryEntryId]);

  return (
    <div className={cn('relative flex h-full', className)}>
      <div className="absolute bottom-5 left-5 z-50 h-12 rounded-sm bg-white shadow-md">
        <div className="flex h-full items-center justify-center">
          <div className="flex w-full">
            {mapStyleStore.availableStyles.map((mapStyle) => {
              const isActive = mapStyle.target === mapStyleStore.selectedStyle;

              return (
                <Button
                  key={mapStyle.target}
                  variant="tabInactive"
                  onClick={() => mapStyleStore.setStyle(mapStyle.target)}
                  className={cn('h-12 text-onSurface-mediumEmphasis', isActive && 'text-onSurface-highEmphasis')}
                >
                  {mapStyle.label}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
      <Map
        onLoad={() => setIsInitialized(true)}
        onRemove={() => setIsInitialized(false)}
        ref={mapRef}
        {...viewState}
        onMove={(e) => setViewState(e.viewState)}
        mapLib={mapboxgl}
        mapboxAccessToken={CONFIG.MAPBOX_ACCESS_TOKEN}
        mapStyle={mapStyleStore.selectedStyle}
      >
        {isInitialized && (
          <>
            {selectedHistoryEntry && selectedHistoryEntry.location && (
              <Marker longitude={selectedHistoryEntry.location.lng} latitude={selectedHistoryEntry.location.lat}>
                <MarkerPin containerType={container.type} />
              </Marker>
            )}

            {hoveredHistoryEntry && hoveredHistoryEntry.location && (
              <Marker longitude={hoveredHistoryEntry.location.lng} latitude={hoveredHistoryEntry.location.lat}>
                <MarkerPin containerType={container.type} variant={'highlighted'} />
              </Marker>
            )}

            <Source type="geojson" data={historyLineGeoJson}>
              <Layer
                type="line"
                paint={{ 'line-color': '#4B86D0', 'line-width': 4 }}
                layout={{ 'line-join': 'round', 'line-cap': 'round' }}
              />
            </Source>
          </>
        )}
      </Map>
    </div>
  );
}
