import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../utils';

// TODO line height & tracking | 17.10.2023 jl

const textStyles = cva('', {
  variants: {
    variant: {
      heading1: 'text-xl font-medium',
      heading2: 'text-lg font-medium',
      heading3: 'font-medium',
      heading4: '',
      body1: '',
      body2: 'text-sm',
      subtitle1: 'text-lg font-medium',
      subtitle2: 'opacity-60',
      caption: 'font-medium',
      overline: 'text-sm uppercase tracking-widest font-medium',
    },
  },
  defaultVariants: {
    variant: 'body1',
  },
});

type Props = {
  className?: string;
} & VariantProps<typeof textStyles> &
  React.ComponentProps<'span'>;

export function Text({ variant, className, children, ...props }: Props) {
  return (
    <span className={cn(textStyles({ variant }), className)} {...props}>
      {children}
    </span>
  );
}
