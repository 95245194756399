import { VariantProps, cva } from 'class-variance-authority';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineError from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

const notificationStyles = cva(
  'rounded flex justify-between items-center gap-4 px-4 py-3 font-medium text-lg min-w-[340px] shadow',
  {
    variants: {
      variant: {
        info: 'bg-gray-800 text-white',
        success: 'bg-communication-success text-white',
        warning: 'bg-communication-warning text-onSurface-highEmphasis',
        error: 'bg-communication-error text-white',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  }
);

const icons = {
  info: InfoIcon,
  success: TaskAltIcon,
  warning: WarningAmberIcon,
  error: ErrorOutlineError,
};

type Props = {
  onClose: () => void;
} & VariantProps<typeof notificationStyles> &
  React.ComponentProps<'div'>;

export function Notification({ variant, onClose, children, ...props }: Props) {
  const Icon = icons[variant!];

  return (
    <div className={notificationStyles({ variant })} {...props}>
      <div className="flex items-center gap-4">
        <Icon sx={{ fontSize: 28 }} />

        <span>{children}</span>
      </div>

      <button onClick={onClose}>
        <CloseIcon />
      </button>
    </div>
  );
}
