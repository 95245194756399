import { Text } from '@ui/Text';
import illuNoData from '@assets/icons/illu-noData.svg';

export function CapNoData() {
  return (
    <div className="flex w-full justify-center p-6">
      <div className="grid justify-items-center py-[10%] text-center">
        <img src={illuNoData} alt="No Data" />

        <Text variant="heading3" className="pt-8">
          Keine Daten verfügbar
        </Text>
        <Text variant="subtitle2" className="mt-1 max-w-sm text-sm">
          Dieser Sensor hat bisher keine Daten erfasst.
        </Text>
      </div>
    </div>
  );
}
