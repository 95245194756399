import { useSessionStore } from '@stores/sessionStore';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export function Logout() {
  const sessionStore = useSessionStore();
  const queryClient = useQueryClient();

  useEffect(() => {
    localStorage.removeItem('session_token');
    sessionStore.clearSession();
    queryClient.clear();
  }, []);

  return null;
}
