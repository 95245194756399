import InfoIcon from '@mui/icons-material/Info';
import iconDanger from '@assets/icons/icon-danger.svg';
import illuLine from '@assets/icons/illu-line.svg';
import iconContainerMultiple from '@assets/icons/24px/icon-container-multiple-dark-24px.svg';
import { Text } from '@ui/Text';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../utils';

const IndicatorStyles = cva(
  'absolute top-[1px] h-[5px] w-[calc(100%-96px)] ml-[96px] [clip-path:polygon(0%_0%,100%_0%,100%_5px,5px_5px)] rounded-tr-sm',
  {
    variants: {
      variant: {
        neutral: 'bg-gray-700',
        danger: 'bg-communication-warning',
      },
    },
    defaultVariants: {
      variant: 'neutral',
    },
  }
);
const IconStyles = cva('', {
  variants: {
    variant: {
      neutral: 'invisible',
      danger: 'visible',
    },
  },
  defaultVariants: {
    variant: 'neutral',
  },
});

type Props = {
  headline: string;
  className?: string;
} & React.ComponentProps<'div'> &
  VariantProps<typeof IndicatorStyles>;

export function ContainerMarkerCluster({ children, headline, onClick, variant, ...props }: Props) {
  return (
    <div className="relative">
      <div className="flex h-12 w-12 items-center justify-center rounded-full border-[1px] border-solid border-gray-700">
        <div className="flex h-[42px] w-[42px] items-center justify-center rounded-full border-[1px] border-solid border-gray-700 bg-[#ffffffcc]">
          <img src={iconContainerMultiple} alt="Multiple Container" />
        </div>
      </div>

      <div className="absolute bottom-[42px] left-[89px] w-[270px]">
        <div className="relative w-full" {...props}>
          <div className="h-full w-full bg-gray-700 drop-shadow-[0px_3px_3px_rgba(0,0,0,0.32)] filter [clip-path:polygon(0%_0%,92px_0%,100px_8px,100%_8px,100%_100%,0%_100%)]">
            <div className="mx-[1px] my-[1px] h-full bg-surface-bright [clip-path:polygon(0%_1px,90px_1px,98px_9px,100%_9px,100%_calc(100%-1px),0%_calc(100%-1px))]">
              <div className="flex justify-between px-3 pb-3 pt-4">
                <div className="flex items-center gap-3">
                  {<InfoIcon />}
                  <Text variant="overline">{headline}</Text>
                </div>
                <img src={iconDanger} alt="Warning Icon" className={cn(IconStyles({ variant }))} />
              </div>
            </div>
          </div>
        </div>

        <div className={cn(IndicatorStyles({ variant }))} />
        <div className="absolute left-[-1.5px] top-[-1px] h-2 w-2 border-l-[3px] border-t-[3px] border-gray-700" />
        <div className="absolute bottom-[-1.5px] left-[-1.5px] h-2 w-2 border-b-[3px] border-l-[3px] border-gray-700" />
        <div className="absolute bottom-[-1.5px] right-[-1.5px] h-2 w-2 border-b-[3px] border-r-[3px] border-gray-700" />
      </div>

      <div className="absolute bottom-[38px] left-[39px] z-50 h-[31px] w-[50px]">
        <img src={illuLine} />
      </div>
    </div>
  );
}
