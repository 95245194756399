import { useDesktopHeaderStore } from '@stores/desktopHeaderStore';
import { useEffect } from 'react';
import iconCapColorfull from '@assets/icons/24px/icon-cap-colorfull-24px.svg';
import iconContainerIbcColorfull from '@assets/icons/24px/icon-container-ibc-colorfull-24px.svg';
import iconContainerTankColorfull from '@assets/icons/24px/icon-container-tank-colorfull-24px.svg';
import { Text } from '@ui/Text';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@hooks/useApi';

export function Dashboard() {
  const desktopHeaderStore = useDesktopHeaderStore();
  const api = useApi();

  useEffect(() => {
    desktopHeaderStore.setHeader({ title: 'Dashboard' });
  }, []);

  const rfidCarrierQuery = useQuery({
    queryKey: ['rfidCarriers'],
    queryFn: api.rfidCarriers.getAllRfidCarriers,
  });

  if (rfidCarrierQuery.isLoading) {
    return <p>Loading...</p>; // TODO better loading | 15.01.2024 jl
  }

  const rfidCarriers = rfidCarrierQuery.data?.data.data || [];

  const rfidCarriersWithoutContainer = rfidCarriers.filter((x) => x.container === null);
  const rfidCarriersWithIBCContainer = rfidCarriers.filter((x) => x.container?.type === 'ibc');
  const rfidCarriersWithTankContainer = rfidCarriers.filter((x) => x.container?.type === 'tank_container');

  return (
    <div className="mx-auto flex w-full max-w-[1520px] flex-col gap-7 p-7">
      <div>
        <h2>
          <Text variant="heading3">SupplyLine Caps Bestand</Text>
        </h2>

        <div className="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div className="col-span-1 rounded bg-white p-4 shadow">
            <div className="flex items-center gap-5">
              <img src={iconCapColorfull} alt="" className="h-6" />

              <h3 className="text-blue-700">
                <Text variant="body2">
                  <span className="mr-0.5 font-semibold">{rfidCarriersWithoutContainer.length}</span>{' '}
                  {rfidCarriersWithoutContainer.length === 1 ? 'SupplyLine Cap' : 'SupplyLine Caps'} auf Lager
                </Text>
              </h3>
            </div>
          </div>

          <div className="rounded bg-white p-4 shadow">
            <div className="flex items-center gap-5">
              <img src={iconContainerIbcColorfull} alt="" className="h-6" />

              <h3 className="text-blue-700">
                <Text variant="body2">
                  <span className="mr-0.5 font-semibold">{rfidCarriersWithIBCContainer.length}</span>{' '}
                  {rfidCarriersWithIBCContainer.length === 1 ? 'SupplyLine Cap' : 'SupplyLine Caps'} in IBC verbaut
                </Text>
              </h3>
            </div>
          </div>

          <div className="rounded bg-white p-4 shadow">
            <div className="flex items-center gap-5">
              <img src={iconContainerTankColorfull} alt="" className="h-6" />

              <h3 className="text-blue-700">
                <Text variant="body2">
                  <span className="mr-0.5 font-semibold">{rfidCarriersWithTankContainer.length}</span>{' '}
                  {rfidCarriersWithTankContainer.length === 1 ? 'SupplyLine Cap' : 'SupplyLine Caps'} in TC verbaut
                </Text>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
