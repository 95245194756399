import { Text } from '@ui/Text';
import { Input } from '@ui/Input';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@hooks/useApi';
import { BatteryStatus } from '@components/BatteryStatus';
import iconContainerTankDark from '@assets/icons/24px/icon-container-tank-dark-24px.svg';
import iconContainerIbcDark from '@assets/icons/24px/icon-container-ibc-dark-24px.svg';
import SvgIcon from '@mui/material/SvgIcon';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ContainersMap } from './ContainersMap';
import { cn } from '../../../../utils';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Link, useNavigate } from 'react-router-dom';

function isDateLessThan28DaysFromNow(date: Date): boolean {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + 28 * 24 * 60 * 60 * 1000);

  return date < futureDate;
}

function isTempOutOfRange(temp: number): boolean {
  return temp < 15 || temp > 35;
}

function millisecondsToDaysAndHours(milliseconds: number): string {
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  const formattedHours = hours.toString().padStart(2, '0');

  return `${days} T, ${formattedHours} Std`;
}

const sixtyDays = 60 * 24 * 60 * 60 * 1000;

export function DataContainers() {
  const api = useApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>('');
  const [selectedContainerId, setSelectedContainerId] = useState<string | undefined>();

  const containersQuery = useQuery({
    queryKey: ['containers'],
    queryFn: api.containers.getAllContainers,
  });

  const updateContainerMutation = useMutation({
    mutationFn: api.containers.updateContainer,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['containers']);
    },
  });

  let containers = containersQuery.data?.data.data || [];
  if (search) {
    containers = containers.filter((c) => {
      const sensor = c.rfidCarriers.find((x) => x.type === 'sensor');
      const searchString = [
        c.serial,
        c.lifetimeExpiresAt?.toLocaleString(),
        sensor?.serial,
        c.chemical?.name,
        `${c.location?.countryCode} - ${c.zone?.name || c.location?.address}`,
        c.zone?.name,
        c.temperature,
        sensor?.batteryLevel,
      ]
        .join(';')
        .toLowerCase();
      return searchString.includes(search.toLowerCase());
    });
  }

  const selectedContainer = containers.find((c) => c.id === selectedContainerId);

  return (
    <div className="grid h-[calc(100vh-114px)] grid-cols-1 grid-rows-2 gap-0 lg:grid-cols-3 lg:grid-rows-1">
      <div className={selectedContainer ? 'row-span-1 lg:col-span-2' : 'row-span-2 lg:col-span-3'}>
        <div className="mb-5 flex h-full flex-col border-r-[1px] bg-white shadow-md">
          <div className="flex p-3 sm:p-6">
            <Input
              variant={'search'}
              LeadingIcon={<SearchIcon className="text-onSurface-highEmphasis" />}
              type="search"
              label="Suche"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="h-full overflow-x-auto overflow-y-auto border-t border-gray-100 pb-6">
            <table className="w-full table-auto whitespace-nowrap text-sm">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th className="px-2 py-4 pl-6 text-left">
                    <Text variant="heading3">Typ</Text>
                  </th>
                  <th className="px-2 py-4 text-left">
                    <Text variant="heading3">Container S/N</Text>
                  </th>
                  <th className="px-2 py-4 text-left">
                    <Text variant="heading3">Lebenszeit</Text>
                  </th>
                  <th className="px-2 py-4 text-left">
                    <Text variant="heading3">Cap S/N</Text>
                  </th>
                  <th className="px-2 py-4 text-left">
                    <Text variant="heading3">Chemie</Text>
                  </th>
                  <th className="px-2 py-4 text-left">
                    <Text variant="heading3">Standort</Text>
                  </th>
                  <th className="px-2 py-4 text-left">
                    <Text variant="heading3">Aufenthalt</Text>
                  </th>
                  <th className="px-2 py-4 text-left">
                    <Text variant="heading3">Zuletzt gesehen</Text>
                  </th>
                  <th className="px-2 py-4 text-left">
                    <Text variant="heading3">Status</Text>
                  </th>
                  <th className="px-2 py-4 text-right">
                    <Text variant="heading3">Aktuelle Temp.</Text>
                  </th>
                  <th className="px-2 py-4 text-center">
                    <Text variant="heading3">Temp.</Text>
                  </th>
                  <th className="px-2 py-4 text-center">
                    <Text variant="heading3">Schock</Text>
                  </th>
                  <th className="px-2 py-4 pr-6 text-right">
                    <Text variant="heading3">Akkustand</Text>
                  </th>
                </tr>

                <tr>
                  {/* Workaround to get a bottom border for the header. Setting border-b to the sticky thead doesn't work because of the border-collapse. */}
                  <th colSpan={14} className="h-[1px] bg-gray-50"></th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-100 border-b border-gray-100">
                {containers.map((container) => {
                  const sensor = container.rfidCarriers.find((x) => x.type === 'sensor');

                  return (
                    <tr
                      key={container.id}
                      className={cn(
                        'hover:bg-surface-bright',
                        selectedContainer?.id === container.id && 'bg-surface-dark'
                      )}
                    >
                      <td className="px-2 py-0.5 pl-6">
                        {container.type === 'tank_container' && (
                          <img src={iconContainerTankDark} alt="Tank Container" />
                        )}

                        {container.type === 'ibc' && <img src={iconContainerIbcDark} alt="IBC" />}
                      </td>

                      <td className="px-2 py-0.5">
                        <Link
                          to={`/desktop/data/containers/${container.id}`}
                          className="cursor-pointer gap-2 text-communication-add hover:underline"
                        >
                          <Text className="font-mono">{container.serial}</Text>
                        </Link>
                      </td>

                      <td className="px-2 py-0.5">
                        {container.lifetimeExpiresAt ? (
                          <span
                            className={cn(
                              'rounded-sm px-2.5 font-mono',
                              isDateLessThan28DaysFromNow(container.lifetimeExpiresAt) &&
                                'bg-[#D54F4940] text-communication-error'
                            )}
                          >
                            <Text className="font-mono">
                              {container.lifetimeExpiresAt?.toLocaleDateString('de-DE', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                timeZone: 'UTC',
                              })}

                              {container.nextInspectionType && (
                                <span className="ml-2 text-xs">
                                  ({container.nextInspectionType === 'minor' ? 'kleiner TÜV' : 'großer TÜV'})
                                </span>
                              )}
                            </Text>
                          </span>
                        ) : (
                          <Text>-</Text>
                        )}
                      </td>

                      <td className="px-2 py-0.5">
                        {sensor?.serial ? <Text className="font-mono">{sensor.serial}</Text> : <Text>-</Text>}
                      </td>

                      <td className="px-2 py-0.5">
                        <Text>{container.chemical?.name || '-'}</Text>
                      </td>

                      <td className="px-2 py-0.5 ">
                        {container.location ? (
                          <Text>
                            <span
                              role="button"
                              onClick={() => setSelectedContainerId(container.id)}
                              className="cursor-pointer gap-2 text-communication-add hover:underline"
                            >
                              {container.location.countryCode} - {container.zone?.name || container.location.address}
                            </span>
                          </Text>
                        ) : (
                          <Text>-</Text>
                        )}
                      </td>

                      <td className="px-2 py-0.5">
                        {container.location && container.lastSeenAt && container.lastMovedAt ? (
                          <span
                            className={cn(
                              'rounded-sm px-2 text-left',
                              container.lastSeenAt.getTime() - container.lastMovedAt.getTime() > sixtyDays &&
                                'bg-[#D54F4940] text-communication-error'
                            )}
                          >
                            <Text className="font-mono">
                              {millisecondsToDaysAndHours(
                                container.lastSeenAt.getTime() - container.lastMovedAt.getTime()
                              )}
                            </Text>
                          </span>
                        ) : (
                          <Text className="px-2">-</Text>
                        )}
                      </td>

                      <td className="px-2 py-0.5">
                        {container.lastSeenAt ? (
                          <Text>
                            {container.lastSeenAt?.toLocaleString('de-DE', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                          </Text>
                        ) : (
                          <Text>-</Text>
                        )}
                      </td>

                      <td className="px-2 py-0.5">
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger asChild>
                            {container.isFilled ? (
                              <button className="w-full rounded-sm bg-[#CCF1D6] px-2.5 text-center outline-none hover:bg-[#B2E8C3]">
                                <Text className="font-mono transition-all">
                                  {updateContainerMutation.variables?.containerId === container.id &&
                                  updateContainerMutation.isLoading ? (
                                    <RefreshIcon sx={{ fontSize: 14 }} className="animate-spin text-[#4BBC7A]" />
                                  ) : (
                                    <span>voll</span>
                                  )}
                                </Text>
                              </button>
                            ) : (
                              <button className="w-full rounded-sm bg-gray-100 px-2.5 text-center outline-none hover:bg-gray-200">
                                <Text className="font-mono transition-all">
                                  {updateContainerMutation.variables?.containerId === container.id &&
                                  updateContainerMutation.isLoading ? (
                                    <RefreshIcon sx={{ fontSize: 14 }} className="animate-spin text-gray-600" />
                                  ) : (
                                    <span>leer</span>
                                  )}
                                </Text>
                              </button>
                            )}
                          </DropdownMenu.Trigger>

                          <DropdownMenu.Portal>
                            <DropdownMenu.Content
                              side="right"
                              sideOffset={2}
                              className="animate-slideRightAndFade overflow-hidden rounded-md border border-gray-50 bg-white shadow-md"
                            >
                              <DropdownMenu.RadioGroup
                                className="flex flex-col divide-y divide-gray-50"
                                value={container.isFilled ? 'filled' : 'empty'}
                                onValueChange={(value) => {
                                  const newIsFilled = value === 'filled';
                                  if (container.isFilled === newIsFilled) return;

                                  updateContainerMutation.mutate({
                                    containerId: container.id,
                                    data: { isFilled: newIsFilled },
                                  });
                                }}
                              >
                                {[
                                  { label: 'voll', value: 'filled' },
                                  { label: 'leer', value: 'empty' },
                                ].map((el) => (
                                  <DropdownMenu.RadioItem
                                    key={el.value}
                                    value={el.value}
                                    className={`flex cursor-pointer items-center gap-1 px-5 py-1 text-center text-sm outline-none ${
                                      el.value === 'filled'
                                        ? 'hover:bg-[#CCF1D6] focus:bg-[#CCF1D6]'
                                        : 'hover:bg-gray-100 focus:bg-gray-100'
                                    } `}
                                  >
                                    <Text className="font-mono">{el.label}</Text>
                                  </DropdownMenu.RadioItem>
                                ))}
                              </DropdownMenu.RadioGroup>
                            </DropdownMenu.Content>
                          </DropdownMenu.Portal>
                        </DropdownMenu.Root>
                      </td>

                      <td className="px-2 py-0.5 text-right">
                        {container.temperature ? (
                          <div
                            className={cn(
                              'rounded-sm px-2.5',
                              isTempOutOfRange(container.temperature) && 'bg-[#D54F4940] text-communication-error'
                            )}
                          >
                            <Text className="font-mono">{container.temperature.toFixed(1)}°C</Text>
                          </div>
                        ) : (
                          <Text>-</Text>
                        )}
                      </td>

                      <td className="px-2 py-0.5 text-center">
                        <span
                          role="button"
                          onClick={() => navigate(`/desktop/data/containers/${container.id}`)}
                          className="relative cursor-pointer"
                        >
                          {container.notAcknowledgedTemperatureWarningsCount > 0 && (
                            <span className="absolute right-0 top-0 flex h-3.5 w-3.5 translate-x-2/3 items-center justify-center rounded-full bg-communication-error text-[0.625rem] font-medium text-white">
                              {container.notAcknowledgedTemperatureWarningsCount}
                            </span>
                          )}
                          <TemperatureIcon showWarning={container.notAcknowledgedTemperatureWarningsCount > 0} />
                        </span>
                      </td>

                      <td className="px-2 py-0.5 text-center">
                        <span
                          role="button"
                          onClick={() => navigate(`/desktop/data/containers/${container.id}`)}
                          className="relative cursor-pointer"
                        >
                          {container.notAcknowledgedShockWarningsCount > 0 && (
                            <span className="absolute right-0 top-0 flex h-3.5 w-3.5 translate-x-full items-center justify-center rounded-full bg-communication-error text-[0.625rem] font-medium text-white">
                              {container.notAcknowledgedShockWarningsCount}
                            </span>
                          )}
                          <ShockIcon showWarning={container.notAcknowledgedShockWarningsCount > 0} />
                        </span>
                      </td>

                      <td className="px-2 py-0.5 pr-6 text-right font-mono">
                        <div className="flex items-center justify-end gap-2">
                          {sensor && sensor.batteryLevel !== null ? (
                            <>
                              <Text>{sensor.batteryLevel}%</Text>
                              <BatteryStatus batteryLevel={sensor.batteryLevel} />
                            </>
                          ) : (
                            <>
                              <Text>-</Text>
                              <BatteryStatus batteryLevel={null} />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {selectedContainer && selectedContainer.location && (
        <div className="h-full bg-gray-600">
          <ContainersMap
            // re-create object no narrow nullable location type. Makes TypeScript happy and me unhappy :(
            container={{ ...selectedContainer, location: selectedContainer.location }}
            onClose={() => setSelectedContainerId(undefined)}
          />
        </div>
      )}
    </div>
  );
}

function TemperatureIcon({ showWarning }: { showWarning?: boolean }) {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="icon-warning-temp" transform="translate(-944.927 -379.5)">
          <path
            id="device_thermostat"
            className={showWarning ? 'fill-communication-error' : 'fill-onSurface-lowEmphasis'}
            d="M285-860a4.819,4.819,0,0,1-3.537-1.463A4.819,4.819,0,0,1,280-865a4.885,4.885,0,0,1,.525-2.237A5.084,5.084,0,0,1,282-869v-8a2.893,2.893,0,0,1,.875-2.125A2.893,2.893,0,0,1,285-880a2.893,2.893,0,0,1,2.125.875A2.893,2.893,0,0,1,288-877v8a5.083,5.083,0,0,1,1.475,1.763A4.885,4.885,0,0,1,290-865a4.819,4.819,0,0,1-1.462,3.537A4.819,4.819,0,0,1,285-860Zm-1-11h2v-1h-1v-1h1v-2h-1v-1h1v-1a.968.968,0,0,0-.288-.713A.968.968,0,0,0,285-878a.968.968,0,0,0-.713.287A.968.968,0,0,0,284-877Z"
            transform="translate(671.943 1261.531)"
            fill="#DADADA"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

function ShockIcon({ showWarning }: { showWarning?: boolean }) {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="icon-warning-shock" transform="translate(-944.927 -379.5)">
          <path
            id="earthquake"
            className={showWarning ? 'fill-communication-error' : 'fill-onSurface-lowEmphasis'}
            d="M87.025-860a1.027,1.027,0,0,1-.613-.187.956.956,0,0,1-.362-.512L83.5-869H80v-2h4.25a.987.987,0,0,1,.587.188.954.954,0,0,1,.362.512l1.65,5.375L90.025-879.2a.99.99,0,0,1,.35-.575A.96.96,0,0,1,91-880a1,1,0,0,1,.625.213.935.935,0,0,1,.35.562l2.175,9.4,1.4-4.475a.955.955,0,0,1,.363-.512A.987.987,0,0,1,96.5-875a.978.978,0,0,1,.575.175,1.064,1.064,0,0,1,.375.475L98.7-871H100v2H98a.977.977,0,0,1-.575-.175,1.064,1.064,0,0,1-.375-.475l-.475-1.275L94.95-865.7a1,1,0,0,1-.375.525.919.919,0,0,1-.625.175,1.011,1.011,0,0,1-.6-.237.974.974,0,0,1-.325-.538L91-874.475l-3.025,13.7a.9.9,0,0,1-.337.55A1.118,1.118,0,0,1,87.025-860Z"
            transform="translate(866.927 1261.459)"
            fill="#DADADA"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
