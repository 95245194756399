import { Text } from '@ui/Text';

type Props = {
  Icon?: React.ReactNode;
  title: string;
};

export function PublicHeader({ Icon, title }: Props) {
  return (
    <header className="bg-gradient-to-br from-[#507F97] to-[#6793A0]">
      <div className="flex flex-col py-5 pl-5 pr-3">
        <div className="flex items-center gap-5">
          {Icon}

          <h1>
            <Text variant="heading1" className="text-white">
              {title}
            </Text>
          </h1>
        </div>
      </div>
    </header>
  );
}
