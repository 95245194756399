import React from 'react';
import { cn } from '../../utils';

type Props = {} & React.ComponentProps<'button'>;

export const IconButton = React.forwardRef<HTMLButtonElement, Props>(({ children, className, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={cn('flex items-center justify-center rounded-full p-3 transition-colors hover:bg-gray-100', className)}
      {...props}
    >
      {children}
    </button>
  );
});
