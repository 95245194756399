import toast from 'react-hot-toast';
import { Notification } from '@ui/Notification';
import { cn } from './utils';

type NotificationData = {
  type: 'info' | 'success' | 'warning' | 'error';
  message: string;
};

export function notify(data: NotificationData) {
  const { type, message } = data;

  toast.custom(
    (t) => (
      <div className={cn(t.visible ? 'animate-enter' : 'animate-leave')} {...t.ariaProps}>
        <Notification variant={type} onClose={() => toast.dismiss(t.id)}>
          {message}
        </Notification>
      </div>
    ),
    { duration: 10_000 }
  );
}
