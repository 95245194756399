import { NavLink, Outlet } from 'react-router-dom';
import { useDesktopHeaderStore } from '@stores/desktopHeaderStore';
import { useEffect } from 'react';
import { Button } from '@ui/Button';

const navItems = [
  {
    label: 'Container',
    target: '/desktop/data/containers',
  },
  {
    label: 'SupplyLine Caps',
    target: '/desktop/data/sensors',
  },
  {
    label: 'Zonen',
    target: '/desktop/data/zones',
  },
];

export function DataLayout() {
  const desktopHeaderStore = useDesktopHeaderStore();

  // TODO don't set it here but in each separate route | 30.01.2024 jl
  useEffect(() => {
    desktopHeaderStore.setHeader({ title: 'Stammdaten' });
  }, []);

  return (
    <div>
      <div className="flex w-full overflow-y-auto pt-3 sm:px-20">
        {navItems.map((navItem) => {
          return (
            <NavLink key={navItem.target} to={navItem.target}>
              {({ isActive }) => <Button variant={isActive ? 'tabActive' : 'tabInactive'}>{navItem.label}</Button>}
            </NavLink>
          );
        })}
      </div>

      <div>
        <Outlet />
      </div>
    </div>
  );
}
