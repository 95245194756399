import { useState } from 'react';
import { RemoveRfidCarrierDialog } from './RemoveRfidCarrierDialog';
import { AddRfidCarrierCard } from './AddRfidCarrierCard';
import { Transition } from '@headlessui/react';
import { DataWrapper } from '@ui/DataWrapper';
import { Text } from '@ui/Text';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import AddLinkIcon from '@mui/icons-material/AddLink';
import iconContainerTankBlue from '@assets/icons/40px/icon-container-tank-blue-40px.svg';
import iconContainerIbcBlue from '@assets/icons/40px/icon-container-ibc-blue-40px.svg';
import iconCapBlue from '@assets/icons/40px/icon-cap-blue-40px.svg';

type Props = {
  container: {
    id: string;
    type: 'ibc' | 'tank_container';
    serial: string;
    rfidCarriers: {
      id: string;
    }[];
  };
  onContainerCardClick: () => void;
  onCapCardClick: () => void;
};

export function Composition({ container, onContainerCardClick, onCapCardClick }: Props) {
  const [rfidCarrierToRemove, setRfidCarrierToRemove] = useState<string | undefined>(undefined);
  const [addRfidCarrierIsOpen, setAddRfidCarrierIsOpen] = useState<boolean>(false);

  return (
    <div className="px-6 py-8">
      <div className="flex flex-col gap-3">
        <div
          className="cursor-pointer rounded bg-white px-5 py-5 shadow transition-colors hover:shadow-lg"
          onClick={() => onContainerCardClick()}
        >
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-7">
              {container.type === 'ibc' ? (
                <img src={iconContainerIbcBlue} alt="IBC Container" />
              ) : (
                <img src={iconContainerTankBlue} alt="Tank Container" />
              )}

              <h2>
                <Text variant="heading2">Container</Text>
              </h2>
            </div>
            <DataWrapper label="Container S/N" value={container.serial} />
          </div>
        </div>

        {container.rfidCarriers.map((rfidCarrier) => (
          <div key={rfidCarrier.id} className="flex items-center gap-3">
            <button
              onClick={() => setRfidCarrierToRemove(rfidCarrier.id)}
              className="rounded bg-white p-2 shadow transition-colors hover:shadow-lg"
            >
              <LinkOffIcon sx={{ fontSize: 24 }} />
            </button>

            <div
              className="w-full cursor-pointer rounded bg-white px-5 py-5 shadow transition-colors hover:shadow-lg"
              onClick={() => onCapCardClick()}
            >
              <div className="flex items-center gap-7">
                <img src={iconCapBlue} alt="SupplyLine Cap" />

                <h2>
                  <Text variant="heading2">SupplyLine Cap</Text>
                </h2>
              </div>
            </div>
          </div>
        ))}

        <Transition show={addRfidCarrierIsOpen}>
          <AddRfidCarrierCard containerId={container.id} onClose={() => setAddRfidCarrierIsOpen(false)} />
        </Transition>

        <Transition show={!addRfidCarrierIsOpen && container.rfidCarriers.length === 0}>
          <button
            onClick={() => setAddRfidCarrierIsOpen(true)}
            className="fixed bottom-24 right-6 rounded-full bg-blue-600 p-4 text-white shadow transition-colors hover:bg-blue-700 xl:relative xl:left-[920px] xl:top-[450px]"
          >
            <AddLinkIcon sx={{ fontSize: 24 }} />
          </button>
        </Transition>
      </div>

      <RemoveRfidCarrierDialog rfidCarrierId={rfidCarrierToRemove} onClose={() => setRfidCarrierToRemove(undefined)} />
    </div>
  );
}
