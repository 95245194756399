import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Button } from '@ui/Button';
import { Text } from '@ui/Text';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@hooks/useApi';

type Props = {
  rfidCarrierId: string | undefined;
  onClose: () => void;
};

export function RemoveRfidCarrierDialog({ rfidCarrierId, onClose }: Props) {
  const api = useApi();
  const queryClient = useQueryClient();

  const updateRfidCarrierMutation = useMutation({
    mutationFn: api.rfidCarriers.updateRfidCarrier,
  });

  function handleConfirm() {
    if (!rfidCarrierId) {
      return;
    }

    updateRfidCarrierMutation.mutate(
      {
        id: rfidCarrierId,
        data: { containerId: null },
      },
      {
        onSuccess: async (res) => {
          if (!res.ok) {
            // extremely unlikely, so we don't do any elaborate error handling
            onClose();
            return;
          }

          await queryClient.invalidateQueries(['containers']);

          onClose();
        },
      }
    );
  }

  return (
    <Transition show={!!rfidCarrierId} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/10" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            <Dialog.Panel className="w-full max-w-sm rounded bg-white px-7 py-7 shadow">
              <div className="flex flex-col gap-10">
                <Dialog.Title as="div" className="flex items-center gap-3.5">
                  <LinkOffIcon sx={{ fontSize: 30 }} />

                  <h2>
                    <Text variant="heading1">Cap ausbauen</Text>
                  </h2>
                </Dialog.Title>

                <Dialog.Description>
                  <Text>Sie sind dabei, die SupplyLine Cap aus dem Container zu entfernen.</Text>
                </Dialog.Description>

                <div className="flex gap-3">
                  <Button variant="outlined" fullWidth onClick={onClose} disabled={updateRfidCarrierMutation.isLoading}>
                    Abbrechen
                  </Button>

                  <Button fullWidth onClick={handleConfirm} disabled={updateRfidCarrierMutation.isLoading}>
                    Bestätigen
                  </Button>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
