import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../utils';

const buttonStyles = cva(
  'flex items-center justify-center px-4 py-2 uppercase font-medium rounded-sm transition-colors gap-1.5 tracking-wider whitespace-nowrap',
  {
    variants: {
      variant: {
        contained:
          'bg-blue-600 text-white active:bg-blue-400 hover:bg-blue-700 disabled:text-onSurface-lowEmphasis disabled:bg-gray-100 shadow-lg active:shadow-xl disabled:shadow-none',
        containedWarning:
          'bg-communication-warning text-onSurface-highEmphasis active:bg-communication-warningLight hover:bg-communication-warningDark disabled:text-onSurface-lowEmphasis disabled:bg-gray-100 shadow-lg active:shadow-xl disabled:shadow-none',
        outlined:
          'border border-blue-300 text-blue-600 hover:enabled:bg-[#4470860D] active:enabled:bg-blue-100 hover:border-blue-700 disabled:border-gray-300 disabled:text-onSurface-lowEmphasis',
        text: 'text-blue-600 active:enabled:bg-[#44708626] disabled:text-onSurface-lowEmphasis',
        textDanger: 'text-communication-error active:enabled:bg-[#D54F4926] disabled:text-onSurface-lowEmphasis',
        textAdd: 'text-communication-add active:enabled:bg-[#4B86D026] disabled:text-onSurface-lowEmphasis',
        tabActive: 'text-sm rounded-none py-1 px-6 bg-blue-600 text-white',
        tabInactive:
          'text-sm rounded-none py-1 px-6 bg-transparent text-onSurface-mediumEmphasis hover:enabled:bg-[#4470860D] disabled:text-onSurface-lowEmphasis',
        headerTabActive: 'text-md rounded-none py-2 px-6 text-white border-b-2 border-white',
        headerTabInactive:
          'text-md rounded-none py-2 px-6 bg-transparent text-white hover:enabled:bg-[#4470860D] disabled:text-onSurface-lowEmphasis opacity-50 border-white border-b-2 border-opacity-0',
      },
      fullWidth: {
        true: 'w-full',
      },
    },
    defaultVariants: {
      variant: 'contained',
    },
  }
);

type Props = {
  LeadingIcon?: React.ReactNode;
  className?: string;
} & VariantProps<typeof buttonStyles> &
  React.ComponentProps<'button'>;

export function Button({ variant, fullWidth, LeadingIcon, className, children, ...props }: Props) {
  return (
    <button className={cn(buttonStyles({ variant, fullWidth }), className)} {...props}>
      {LeadingIcon}
      {children}
    </button>
  );
}
