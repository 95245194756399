import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../utils';
import iconContainerTankDark from '@assets/icons/24px/icon-container-tank-dark-24px.svg';
import iconIBCContainerDark from '@assets/icons/24px/icon-container-ibc-dark-24px.svg';

const MarkerPinWrapperStyles = cva(
  'flex h-12 w-12 items-center justify-center rounded-full border-[1px] border-solid border-gray-700 ',
  {
    variants: {
      variant: {
        standart: 'bg-none',
        highlighted: 'bg-none border-communication-addDark opacity-80',
      },
    },

    defaultVariants: {
      variant: 'standart',
    },
  }
);

const MarkerPinStyles = cva(
  'flex h-[42px] w-[42px] items-center justify-center rounded-full border-[1px] border-solid border-gray-700 bg-[#ffffffcc]',
  {
    variants: {
      variant: {
        standart: 'bg-none',
        highlighted: 'bg-communication-add bg-opacity-40 border-communication-addDark',
      },
    },

    defaultVariants: {
      variant: 'standart',
    },
  }
);

const markerIcons = {
  ibc: iconIBCContainerDark,
  tank_container: iconContainerTankDark,
};

type Props = {
  containerType: 'ibc' | 'tank_container';
} & VariantProps<typeof MarkerPinStyles> &
  React.ComponentProps<'div'>;

export function MarkerPin({ containerType, variant, className, children, ...props }: Props) {
  const icon = markerIcons[containerType];

  return (
    <div className="group relative" {...props}>
      <div className={cn(MarkerPinWrapperStyles({ variant }), className)}>
        <div className={cn(MarkerPinStyles({ variant }), className)}>
          <img src={icon} alt="Container" />
        </div>
      </div>
    </div>
  );
}
