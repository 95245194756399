import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';

type Props = {
  historyEntries: {
    temperature: {
      temperature: number;
    } | null;
    recordedAt: Date;
  }[];
};

function ContainerDetailsTemperatureGraph({ historyEntries }: Props) {
  const historyEntriesWithTemperature = historyEntries.filter((x) => x.temperature !== null);

  let maxTemperature = historyEntriesWithTemperature[0].temperature!.temperature || 0;
  let minTemperature = historyEntriesWithTemperature[0].temperature!.temperature || 0;

  for (const historyEntry of historyEntriesWithTemperature) {
    if (historyEntry.temperature!.temperature > maxTemperature) {
      maxTemperature = historyEntry.temperature!.temperature;
    }

    if (historyEntry.temperature!.temperature < minTemperature) {
      minTemperature = historyEntry.temperature!.temperature;
    }
  }

  return (
    <ResponsiveLine
      curve="monotoneX"
      enableArea
      enablePoints={false}
      enableSlices="x"
      margin={{ top: 20, right: 50, bottom: 30, left: 50 }}
      theme={{
        background: '#fff',
        grid: { line: { stroke: '#00000011' } },
        axis: { ticks: { text: { fill: '#43434399' } } },
      }}
      sliceTooltip={(foo) => {
        if (foo.axis !== 'x') return null;

        const point = foo.slice.points[0];
        if (!point) return null;

        return (
          <div className="rounded-md bg-white px-3 py-2 text-sm shadow-md">
            {point.data.y as number}°C <span className="mx-1">-</span>{' '}
            {(point.data.x as Date).toLocaleString('de-DE', {
              month: 'short',
              day: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </div>
        );
      }}
      defs={[
        linearGradientDef('gradientA', [
          { offset: 0, color: 'inherit' },
          { offset: 100, color: 'inherit', opacity: 0 },
        ]),
      ]}
      colors={['#B2CD35']}
      fill={[{ match: '*', id: 'gradientA' }]}
      yScale={{
        type: 'linear',
        min: Math.min(minTemperature - 10, 15 - 5),
        max: Math.max(maxTemperature + 10, 35 + 5),
      }}
      xScale={{
        type: 'time',
      }}
      axisBottom={{
        format: (val: Date) => val.toLocaleDateString('de-DE', { day: '2-digit', month: 'short' }),
        tickValues: Math.min(historyEntriesWithTemperature.length, 8),
      }}
      axisLeft={{
        tickPadding: 5,
      }}
      markers={[
        {
          axis: 'y',
          value: 35,
          lineStyle: { stroke: '#FFD08D', strokeWidth: 2 },
        },
        {
          axis: 'y',
          value: 15,
          lineStyle: { stroke: '#FFD08D', strokeWidth: 2 },
        },
      ]}
      data={[
        {
          id: 'temperature',
          data: historyEntriesWithTemperature.map((x) => ({ x: x.recordedAt, y: x.temperature!.temperature })),
        },
      ]}
    />
  );
}

export default ContainerDetailsTemperatureGraph;
