import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../utils';

const ColorStyles = cva('', {
  variants: {
    variant: {
      full: 'fill-communication-success',
      medium: 'fill-communication-success',
      low: 'fill-communication-warning',
      critical: 'fill-communication-error',
      empty: 'fill-communication-error',
      disabled: 'fill-onSurface-lowEmphasis',
    },
  },
  defaultVariants: {
    variant: 'full',
  },
});

const OpacityCharg1Styles = cva('', {
  variants: {
    variant: {
      full: '1',
      medium: '0',
      low: '0',
      critical: '0',
      empty: '0',
      disabled: '1',
    },
  },
  defaultVariants: {
    variant: 'full',
  },
});
const OpacityCharg2Styles = cva('', {
  variants: {
    variant: {
      full: '1',
      medium: '1',
      low: '0',
      critical: '0',
      empty: '0',
      disabled: '1',
    },
  },
  defaultVariants: {
    variant: 'full',
  },
});
const OpacityCharg3Styles = cva('', {
  variants: {
    variant: {
      full: '1',
      medium: '1',
      low: '1',
      critical: '0',
      empty: '0',
      disabled: '1',
    },
  },
  defaultVariants: {
    variant: 'full',
  },
});
const OpacityCharg4Styles = cva('', {
  variants: {
    variant: {
      full: '1',
      medium: '1',
      low: '1',
      critical: '1',
      empty: '0',
      disabled: '1',
    },
  },
  defaultVariants: {
    variant: 'full',
  },
});

type Props = {
  className?: string;
} & VariantProps<typeof ColorStyles>;

export function BatteryIcon({ variant }: Props) {
  return (
    <div className="h-6 w-6">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="icon-battery" transform="translate(-944.927 -379.5)">
          <rect id="BG" width="24" height="24" transform="translate(944.927 379.5)" fill="none" />
          <path
            id="battery-bg"
            d="M11.7,3.206V18.53H1.53V3.206ZM9.661,0H3.543V1.794H1.5A1.45,1.45,0,0,0,0,3.183V18.532A1.449,1.449,0,0,0,1.5,19.92H11.7a1.449,1.449,0,0,0,1.5-1.388V3.183a1.45,1.45,0,0,0-1.5-1.39H9.661Z"
            transform="translate(950.353 381.54)"
            className={cn(ColorStyles({ variant }))}
          />
          <g id="icon-battery-charg" transform="translate(950.352 381.54)">
            <rect
              id="charg_1"
              width="8.991"
              height="2.913"
              transform="translate(2.118 4.193)"
              className={cn(ColorStyles({ variant }))}
              opacity={cn(OpacityCharg1Styles({ variant }))}
            />
            <rect
              id="charg_2"
              width="8.991"
              height="2.913"
              transform="translate(2.118 7.671)"
              className={cn(ColorStyles({ variant }))}
              opacity={cn(OpacityCharg2Styles({ variant }))}
            />
            <rect
              id="charg_3"
              width="8.991"
              height="2.913"
              transform="translate(2.118 11.149)"
              className={cn(ColorStyles({ variant }))}
              opacity={cn(OpacityCharg3Styles({ variant }))}
            />
            <rect
              id="charg_4"
              width="8.991"
              height="2.913"
              transform="translate(2.118 14.627)"
              className={cn(ColorStyles({ variant }))}
              opacity={cn(OpacityCharg4Styles({ variant }))}
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
