import '@fontsource/ibm-plex-sans/latin.css';
import '@fontsource/ibm-plex-mono/latin.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from '@components/App';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { notify } from './notify';
import { ApiInvalidSessionError } from '@hooks/useApi';

// @ts-expect-error
window.designer = function () {
  window.location.href = '/developer/designer';
};

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error) => {
      if (error instanceof ApiInvalidSessionError) {
        notify({ type: 'info', message: 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.' });
        return;
      }

      notify({
        type: 'error',
        message:
          'Es ist ein unerwarteter Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof ApiInvalidSessionError) {
        notify({ type: 'info', message: 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.' });
        return;
      }

      notify({
        type: 'error',
        message:
          'Es ist ein unerwarteter Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
      });
    },
  }),
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
        <Toaster position="bottom-right" />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
