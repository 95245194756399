import { BatteryIcon } from '@ui/BatteryIcon';

type Props = {
  batteryLevel: number | null;
};

export function BatteryStatus({ batteryLevel }: Props) {
  let variant: React.ComponentProps<typeof BatteryIcon>['variant'];

  if (batteryLevel === null) {
    variant = 'disabled';
  } else if (batteryLevel >= 80) {
    variant = 'full';
  } else if (batteryLevel >= 40) {
    variant = 'medium';
  } else if (batteryLevel >= 10) {
    variant = 'low';
  } else if (batteryLevel >= 1) {
    variant = 'critical';
  } else {
    variant = 'empty';
  }

  return <BatteryIcon variant={variant} />;
}
