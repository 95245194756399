import { Text } from '@ui/Text';
import { IconButton } from '@ui/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@ui/Button';

type Props = {
  Icon?: React.ReactNode;
  title: string;
  onClose: () => void;
  tabs: {
    Icon?: React.ReactNode;
    label: string;
    onClick: () => void;
    isActive: boolean;
  }[];
};

export function DetailsHeader({ Icon, title, onClose, tabs }: Props) {
  return (
    <header className="bg-gradient-to-br from-[#507F97] to-[#6793A0] shadow-md">
      <div className="flex flex-col pl-5 pr-3 pt-3">
        <div className="flex justify-between">
          <div className="flex items-center gap-5">
            {Icon}

            <h1>
              <Text variant="heading1" className="text-white">
                {title}
              </Text>
            </h1>
          </div>

          <IconButton className="h-12 w-12" onClick={() => onClose()}>
            <CloseIcon className="text-white" sx={{ fontSize: 28 }} />
          </IconButton>
        </div>

        <div className="pt-5">
          <div className="flex h-12 items-end overflow-x-auto">
            {tabs.map((tab) => (
              <Button
                key={tab.label}
                variant={tab.isActive ? 'headerTabActive' : 'headerTabInactive'}
                onClick={tab.onClick}
                LeadingIcon={tab.Icon}
              >
                {tab.label}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </header>
  );
}
