import { Text } from '@ui/Text';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../utils';

const DataWrapperStyles = cva('break-all text-lg', {
  variants: {
    variant: {
      normal: '',
      mono: 'font-mono',
    },
  },
  defaultVariants: {
    variant: 'normal',
  },
});

type Props = {
  LeadingIcon?: React.ReactNode;
  label: string;
  value: string;
  isError?: boolean;
} & VariantProps<typeof DataWrapperStyles>;

export function DataWrapper({ variant, label, value, LeadingIcon, isError, ...props }: Props) {
  return (
    <div
      className={cn(
        'rounded-sm bg-gray-50 px-5 py-3.5',
        isError && 'border-2 border-communication-error bg-communication-error/5 text-communication-errorDark'
      )}
    >
      <div className="flex items-center">
        {LeadingIcon && <div className="pr-5">{LeadingIcon}</div>}

        <div>
          <p>
            <Text
              variant="body2"
              className={cn('text-onSurface-highEmphasis', isError && 'text-communication-errorDark')}
            >
              {label}
            </Text>
          </p>
          <p className={cn(DataWrapperStyles({ variant }))}>{value}</p>
        </div>
      </div>
    </div>
  );
}
