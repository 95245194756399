import AddLinkIcon from '@mui/icons-material/AddLink';
import iconRFID from '@assets/icons/icon-rfid.svg';
import { Text } from '@ui/Text';
import { Input } from '@ui/Input';
import { Button } from '@ui/Button';
import { z } from 'zod';
import { useApi } from '@hooks/useApi';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import successSound from '@assets/sounds/success.mp3';
import errorSound from '@assets/sounds/error.mp3';

const successAudio = new Audio(successSound);
const errorAudio = new Audio(errorSound);

const formSchema = z.object({
  rfidTagUID: z.string().length(16, 'Die RFID-Chip Nummer muss genau 16 Zeichen lang sein.'),
});

type Props = {
  containerId: string;
  onClose: () => void;
};

export function AddRfidCarrierCard({ containerId, onClose }: Props) {
  const api = useApi();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({ resolver: zodResolver(formSchema) });

  const getRfidCarrierByRfidTagUIDMutation = useMutation({
    mutationFn: api.rfidCarriers.getRfidCarrierByRfidTagUID,
  });

  const updateRfidCarrierMutation = useMutation({
    mutationFn: api.rfidCarriers.updateRfidCarrier,
  });

  const handleSubmit = form.handleSubmit((data) => {
    getRfidCarrierByRfidTagUIDMutation.mutate(
      {
        rfidTagUID: data.rfidTagUID,
      },
      {
        onSuccess: (res) => {
          if (!res.ok) {
            errorAudio.play();

            switch (res.data.errCode) {
              case 'rfid_carrier_not_found': {
                form.setError('rfidTagUID', { message: 'Unbekannter Sensor.' });
                return;
              }
              default: {
                const _exhaustiveCheck: never = res.data.errCode;
                return;
              }
            }
          }

          const rfidCarrier = res.data;
          updateRfidCarrierMutation.mutate(
            { id: rfidCarrier.id, data: { containerId: containerId } },
            {
              onSuccess: async (res) => {
                if (!res.ok) {
                  switch (res.data.errCode) {
                    case 'rfid_carrier_not_found': {
                      // This case should never trigger because we *just* fetched the RFID Carrier.
                      return;
                    }
                    case 'container_not_found': {
                      // This case should never trigger because the Composition must have a valid Container
                      return;
                    }
                    default: {
                      const _exhaustiveCheck: never = res.data.errCode;
                      return;
                    }
                  }
                }

                await queryClient.invalidateQueries(['containers']);

                successAudio.play();

                onClose();
              },
            }
          );
        },
      }
    );
  });

  const isLoading = getRfidCarrierByRfidTagUIDMutation.isLoading || updateRfidCarrierMutation.isLoading;

  return (
    <form onSubmit={handleSubmit} className="flex items-start gap-3">
      <div className="mt-4 rounded bg-white p-2 shadow">
        <AddLinkIcon sx={{ fontSize: 24 }} />
      </div>

      <div className="w-full rounded bg-white px-5 py-5 shadow">
        <div className="flex flex-col gap-6">
          <div className="flex items-center gap-7">
            <img src={iconRFID} alt="" />

            <h2>
              <Text variant="heading2">SupplyLine Cap</Text>
            </h2>
          </div>

          <Input
            type="text"
            label="RFID-Chip"
            required
            errorMessage={form.formState.errors.rfidTagUID?.message}
            {...form.register('rfidTagUID')}
          />

          <div className="flex flex-row-reverse">
            <Button type="submit" disabled={isLoading} className="w-[160px] sm:w-[50%]">
              Zuweisen
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
