import { Link } from 'react-router-dom';
import { useSessionStore } from '@stores/sessionStore';
import { Text } from '@ui/Text';
import BuildIcon from '@mui/icons-material/Build';
import { Header } from '@components/scanner/Header';

export function Home() {
  const sessionStore = useSessionStore();

  function getGreeting() {
    const now = new Date();
    const nowHours = now.getHours();

    if (nowHours < 12) {
      return `Guten Morgen ${sessionStore.user?.name},`;
    } else if (nowHours < 17) {
      return `Guten Nachmittag ${sessionStore.user?.name},`;
    } else {
      return `Guten Abend ${sessionStore.user?.name},`;
    }
  }

  return (
    <>
      <Header title="Home" />

      <main className="h-full overflow-y-auto">
        <div className="px-6 py-8">
          <div>
            <p>
              <Text variant="caption">{getGreeting()}</Text>
            </p>
            <p>
              <Text>Was möchten Sie tun?</Text>
            </p>
          </div>

          <div className="mt-8 flex flex-col gap-4">
            <Link
              to="/scanner/scan"
              className="rounded-md bg-white px-5 py-4 shadow-md transition-colors hover:shadow-lg"
            >
              <div className="flex items-center justify-between gap-5">
                <div>
                  <h2>
                    <Text variant="heading2">Cap ein- / ausbauen</Text>
                  </h2>

                  <div className="mt-1">
                    <p>
                      <Text>
                        Ich möchte eine SupplyLine Cap in einen Container einbauen oder aus einem Container ausbauen.
                      </Text>
                    </p>
                  </div>
                </div>

                <BuildIcon sx={{ fontSize: 28 }} />
              </div>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
