import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Header } from '@components/scanner/Header';
import { Composition as CompositionComponent } from '@components/scanner/Composition/Composition';
import { ContainerDetails } from '@components/scanner/ContainerDetails/ContainerDetails';
import { CapDetails } from '@components/scanner/CapDetails';

type Props = {
  containerId: string;
  onClose: () => void;
};

export function Composition({ containerId, onClose }: Props) {
  const api = useApi();

  const [showContainerDetails, setShowContainerDetails] = useState<boolean>(false);
  const [showCapDetails, setShowCapDetails] = useState<boolean>(false);

  const containersQuery = useQuery({
    queryKey: ['containers', containerId],
    queryFn: () => api.containers.getContainerById({ containerId: containerId }),
  });

  if (containersQuery.isLoading) {
    return 'Loading...';
  }

  const container = containersQuery.data?.ok ? containersQuery.data.data : null;

  if (!container) {
    onClose();
    return;
  }

  if (showContainerDetails) {
    return <ContainerDetails container={container} onClose={() => setShowContainerDetails(false)} />;
  }

  if (showCapDetails) {
    return <CapDetails rfidCarrier={container.rfidCarriers[0]} onClose={() => setShowCapDetails(false)} />;
  }

  return (
    <>
      <Header title="Cap ein- / ausbauen" onBack={() => onClose()} />
      <main className="h-full overflow-y-auto">
        <CompositionComponent
          container={container}
          onContainerCardClick={() => setShowContainerDetails(true)}
          onCapCardClick={() => setShowCapDetails(true)}
        />
      </main>
    </>
  );
}
