import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '@components/scanner/Header';
import { Composition } from './Composition';
import { ContainerPackingNumberCard } from './ContainerPackingNumberCard';
import { SensorCard } from './SensorCard';
import { Text } from '@ui/Text';

export function Scan() {
  const navigate = useNavigate();

  const [containerId, setContainerId] = useState<string | undefined>();
  const [openCard, setOpenCard] = useState<'container_serial' | 'sensor'>('container_serial');

  if (!containerId) {
    return (
      <>
        <Header title="Cap ein- / ausbauen" onBack={() => navigate('/scanner')} />
        <main className="h-full overflow-y-auto">
          <div className="px-6 py-8">
            <div>
              <p>
                <Text>Was möchten Sie zuerst scannen?</Text>
              </p>
            </div>

            <div className="mt-8 flex flex-col gap-4">
              <ContainerPackingNumberCard
                isOpen={openCard === 'container_serial'}
                onOpen={() => setOpenCard('container_serial')}
                setContainerId={setContainerId}
              />

              <SensorCard
                isOpen={openCard === 'sensor'}
                onOpen={() => setOpenCard('sensor')}
                setContainerId={setContainerId}
              />
            </div>
          </div>
        </main>
      </>
    );
  }

  return <Composition containerId={containerId} onClose={() => setContainerId(undefined)} />;
}
