import { Text } from '@ui/Text';
import illuNoSensor from '@assets/icons/illu-noSensor.svg';

type Props = {
  serial: string;
};

export function CapNotFound({ serial }: Props) {
  return (
    <div className="flex w-full justify-center p-6">
      <div className="grid justify-items-center py-[10%] text-center">
        <img src={illuNoSensor} alt="No Sensor" />

        <Text variant="heading3" className="pt-8">
          SupplyLine Cap nicht gefunden
        </Text>
        <Text variant="subtitle2" className="mt-1 max-w-sm text-sm">
          Die SupplyLine Cap{' '}
          <span className="rounded bg-surface-dark px-1.5 py-0.5 font-mono text-xs font-medium">{serial}</span>{' '}
          existiert nicht.
          <br />
          Sollte dies ein Fehler sein, kontaktieren Sie bitte den Support unter{' '}
          <a href={`mailto:support@supplyline.as?subject=SupplyLine Cap ${serial} nicht gefunden`}>
            support@supplyline.as
          </a>
          .
        </Text>
      </div>
    </div>
  );
}
