import * as zustand from 'zustand';

type MapStyle = {
  selectedStyle: string;
  setStyle: (mapStyle: string) => void;
  availableStyles: {
    label: string;
    target: string;
  }[];
};

export const useMapStyleStore = zustand.create<MapStyle>((set) => ({
  selectedStyle: 'mapbox://styles/mapbox/streets-v12',
  setStyle: (mapStyle: string) => set({ selectedStyle: mapStyle }),
  availableStyles: [
    {
      label: 'Standard',
      target: 'mapbox://styles/mapbox/streets-v12',
    },
    {
      label: 'Satellit',
      target: 'mapbox://styles/mapbox/satellite-v9',
    },
    {
      label: 'Dunkel',
      target: 'mapbox://styles/mapbox/navigation-night-v1',
    },
  ],
}));
