import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from '@routes/Login';
import { useSessionStore } from '@stores/sessionStore';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@hooks/useApi';
import { Layout as ScannerLayout } from '@components/scanner/Layout';
import { Home as ScannerHome } from '@routes/scanner/Home';
import { Scan as ScannerScan } from '@routes/scanner/Scan';
import { Layout as DesktopLayout } from '@routes/desktop/Layout';
import { Dashboard as DesktopDashboard } from '@routes/desktop/Dashboard';
import { DataLayout as DesktopDataLayout } from '@routes/desktop/data/DataLayout';
import { DataContainers as DesktopDataContainers } from '@routes/desktop/data/Containers';
import { ContainerDetails as DesktopDataContainerDetails } from '@routes/desktop/data/Containers/ContainerDetails';
import { DataSensors as DesktopDataSensors } from '@routes/desktop/data/Sensors';
import { DataZones as DesktopDataZones } from '@routes/desktop/data/Zones';
import { Cap as PublicScannerCap } from '@routes/public/scanner/Cap';
import { Designer } from './Designer';
import { Faker } from './Faker';
import { Logout } from '@routes/Logout';

export function App() {
  const [initialized, setInitialized] = useState<boolean>(false);
  const sessionStore = useSessionStore();
  const api = useApi();

  const getCurrentSessionMutation = useMutation({
    mutationFn: api.auth.getCurrentSession,
  });

  useEffect(() => {
    const sessionToken = localStorage.getItem('session_token');
    if (!sessionToken) {
      setInitialized(true);
      return;
    }

    sessionStore.setSession({ sessionToken: sessionToken });
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!sessionStore.session) {
      return;
    }

    getCurrentSessionMutation.mutate(undefined, {
      onSuccess: (res) => {
        sessionStore.setUser({
          id: res.data.user.id,
          name: res.data.user.name,
          email: res.data.user.email,
          company: {
            id: res.data.user.company.id,
            name: res.data.user.company.name,
          },
        });
      },
    });
  }, [sessionStore.session]);

  if (!initialized || getCurrentSessionMutation.isLoading) {
    return <p>Loading...</p>; // TODO better loading | 10.10.2023 jl
  }

  const isLoggedIn = !!sessionStore.session && !!sessionStore.user;

  return (
    <Routes>
      <Route path="/public/scanner">
        <Route path="cap/:serial" element={<PublicScannerCap />} />

        <Route path="*" index element={<Navigate to="/" />} />
      </Route>

      {!isLoggedIn && (
        <>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      )}

      {isLoggedIn && (
        <>
          <Route path="/scanner" element={<ScannerLayout />}>
            <Route path="" element={<ScannerHome />} />
            <Route path="scan" element={<ScannerScan />} />
          </Route>

          <Route path="/desktop" element={<DesktopLayout />}>
            <Route path="" element={<DesktopDashboard />} />

            <Route path="data" element={<DesktopDataLayout />}>
              <Route path="containers" element={<DesktopDataContainers />} />
              <Route path="sensors" element={<DesktopDataSensors />} />
              <Route path="zones" element={<DesktopDataZones />} />

              <Route path="*" index element={<Navigate to="/desktop/data/containers" />} />
            </Route>

            <Route path="data/containers/:containerId" element={<DesktopDataContainerDetails />} />
          </Route>

          <Route path="/developer">
            <Route path="designer" element={<Designer />} />
            <Route path="faker" element={<Faker />} />

            <Route path="*" index element={<Navigate to="/" />} />
          </Route>

          <Route path="/logout" element={<Logout />} />

          <Route path="*" element={<Navigate to="/desktop/data" />} />
        </>
      )}
    </Routes>
  );
}
