import { Link, Outlet } from 'react-router-dom';
import { Text } from '@ui/Text';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@ui/IconButton';
import { useDesktopHeaderStore } from '@stores/desktopHeaderStore';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import StorageIcon from '@mui/icons-material/Storage';
import LogoutIcon from '@mui/icons-material/Logout';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const navItems = [
  {
    label: 'Dashboard',
    Icon: SpaceDashboardIcon,
    target: '/desktop',
  },
  {
    label: 'Stammdaten',
    Icon: StorageIcon,
    target: '/desktop/data/containers',
  },
  {
    label: 'Logout',
    Icon: LogoutIcon,
    target: '/logout',
  },
];

export function Layout() {
  const desktopHeaderStore = useDesktopHeaderStore();

  return (
    <div className="flex h-screen flex-col">
      <header className="z-20 flex h-16 items-center justify-between gap-8 bg-white px-6 py-3 shadow-md">
        <div className="flex w-full justify-between">
          <div className="flex items-center gap-8">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger asChild>
                <IconButton className="text-gray-950 outline-none">
                  <MenuIcon />
                </IconButton>
              </DropdownMenu.Trigger>

              <DropdownMenu.Portal>
                <DropdownMenu.Content
                  side="bottom"
                  sideOffset={2}
                  className="z-30 ml-2 flex animate-slideDownAndFade flex-col overflow-hidden rounded border border-gray-50 bg-white shadow-md"
                >
                  {navItems.map((navItem) => (
                    <DropdownMenu.Item key={navItem.target} asChild>
                      <Link
                        to={navItem.target}
                        className="flex items-center gap-5 px-4 py-2 outline-none transition-colors hover:bg-gray-50 focus:bg-gray-50"
                      >
                        <navItem.Icon sx={{ fontSize: 18 }} />

                        <span>{navItem.label}</span>
                      </Link>
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
            <h1>
              <Text variant="heading1">{desktopHeaderStore.header?.title}</Text>
            </h1>
          </div>

          {desktopHeaderStore.header?.actionElement}
        </div>
      </header>
      <main className="relative h-full overflow-y-auto">
        <Outlet />
      </main>
    </div>
  );
}
