import { Layer, Map, MapRef, Source } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import CONFIG from '../../../../config';
import InfoIcon from '@mui/icons-material/Info';
import { Button } from '@ui/Button';
import { Sidesheet } from '@ui/Sidesheet';
import { useEffect, useRef, useState } from 'react';
import { useMapStyleStore } from '@stores/mapStyleStore';
import { bbox, center } from '@turf/turf';
import { cn } from '../../../../utils';

type Props = {
  zone: {
    id: string;
    name: string;
    area: {
      type: 'Polygon';
      coordinates: number[][][];
    };
  };
  onClose: () => void;
};

export function ZonesMap({ zone, onClose }: Props) {
  const mapStyleStore = useMapStyleStore();

  const mapRef = useRef<MapRef>(null);

  const [isInitialized, setIsInitialized] = useState(false);
  const zoneCenter = center(zone.area);
  const [viewState, setViewState] = useState({
    longitude: zoneCenter.geometry.coordinates[0],
    latitude: zoneCenter.geometry.coordinates[1],
    zoom: 8,
  });

  useEffect(() => {
    if (!mapRef.current) return;
    mapRef.current.resize();
  }, [isInitialized]);

  useEffect(() => {
    if (!mapRef.current) return;

    const [minLng, minLat, maxLng, maxLat] = bbox(zone.area);

    mapRef.current.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { duration: 600, padding: 150, essential: true }
    );
  }, [zone, isInitialized]);

  return (
    <div className="relative h-full">
      <div className="absolute right-5 top-5 z-50">
        <Sidesheet
          variant={'neutral'}
          HeaderIcon={<InfoIcon className="text-onSurface-highEmphasis" />}
          headline={zone.name}
          onClose={onClose}
        />
      </div>

      <div className="absolute bottom-5 left-5 z-50 h-12 rounded-sm bg-white shadow-md">
        <div className="flex h-full items-center justify-center">
          <div className="flex w-full">
            {mapStyleStore.availableStyles.map((mapStyle) => {
              const isActive = mapStyle.target === mapStyleStore.selectedStyle;

              return (
                <Button
                  key={mapStyle.target}
                  variant="tabInactive"
                  onClick={() => mapStyleStore.setStyle(mapStyle.target)}
                  className={cn('h-12 text-onSurface-mediumEmphasis', isActive && 'text-onSurface-highEmphasis')}
                >
                  {mapStyle.label}
                </Button>
              );
            })}
          </div>
        </div>
      </div>

      <Map
        onLoad={() => setIsInitialized(true)}
        onRemove={() => setIsInitialized(false)}
        ref={mapRef}
        {...viewState}
        onMove={(e) => setViewState(e.viewState)}
        mapLib={mapboxgl}
        mapboxAccessToken={CONFIG.MAPBOX_ACCESS_TOKEN}
        mapStyle={mapStyleStore.selectedStyle}
      >
        {isInitialized && (
          <Source type="geojson" data={zone.area}>
            <Layer type="fill" paint={{ 'fill-color': '#42B2CE', 'fill-opacity': 0.2 }} />
            <Layer type="line" paint={{ 'line-color': '#42B2CE', 'line-width': 1 }} />
          </Source>
        )}
      </Map>
    </div>
  );
}
