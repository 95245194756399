import * as zustand from 'zustand';

type Session = {
  sessionToken: string;
};

type User = {
  id: string;
  name: string;
  email: string;
  company: {
    id: string;
    name: string;
  };
};

type SessionState = {
  session: Session | null;
  user: User | null;
  setSession: (session: Session) => void;
  setUser: (user: User) => void;
  clearSession: () => void;
};

export const useSessionStore = zustand.create<SessionState>((set) => ({
  session: null,
  user: null,
  setSession: (session: Session) => set({ session: session }),
  setUser: (user: User) => set({ user: user }),
  clearSession: () => {
    set({ session: null, user: null });
  },
}));
