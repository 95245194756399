import { DataWrapper } from '@ui/DataWrapper';
import { Text } from '@ui/Text';
import iconTempRangeDark from '@assets/icons/24px/icon-temp-range-dark-24px.svg';

type Props = {
  cap: {
    id: string;
    container: {
      serial: string;
      temperature: number | null;
      minTemperature: number | null;
      maxTemperature: number | null;
    };
  };
};

export function CapDetails({ cap }: Props) {
  return (
    <div>
      <div className="bg-white px-6 pb-6 pt-2">
        <div className="flex flex-col gap-[10px]">
          <Text variant={'heading3'}>Chemie Daten</Text>
          <DataWrapper variant="mono" label="Container S/N" value={cap.container.serial} />
          <DataWrapper
            variant="mono"
            label="Min/Max Temperatur"
            value="15°C - 35°C"
            LeadingIcon={<img src={iconTempRangeDark} alt="" />}
          />
        </div>
      </div>

      <div className="mt-5 bg-white px-6 pb-6 pt-2">
        <div className="grid grid-cols-1 gap-[10px]">
          <Text variant={'heading3'}>Temperatur Sensor</Text>
          {/* TODO include date of recorded temperature | 19.02.2024 jl */}
          {cap.container.temperature && (
            <DataWrapper
              variant="mono"
              label="Aktuelle Temperatur"
              value={`${cap.container.temperature.toFixed(1)}°C`}
              isError={isTempOutOfRange(cap.container.temperature)}
            />
          )}

          {cap.container.minTemperature && (
            <DataWrapper
              variant="mono"
              label="Min Temperatur"
              value={`${cap.container.minTemperature.toFixed(1)}°C`}
              isError={isTempOutOfRange(cap.container.minTemperature)}
            />
          )}

          {cap.container.maxTemperature && (
            <DataWrapper
              variant="mono"
              label="Max Temperatur"
              value={`${cap.container.maxTemperature.toFixed(1)}°C`}
              isError={isTempOutOfRange(cap.container.maxTemperature)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function isTempOutOfRange(temp: number): boolean {
  return temp < 15 || temp > 35;
}
