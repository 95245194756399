import { Link, useLocation } from 'react-router-dom';
import { useSessionStore } from '@stores/sessionStore';
import { cn } from '../../../utils';
import HomeIcon from '@mui/icons-material/Home';
import { Text } from '@ui/Text';

export function Footer() {
  const sessionStore = useSessionStore();
  const location = useLocation();

  let activeNavItem: 'home' | 'scanner' | 'profile' | undefined = undefined;
  if (location.pathname.startsWith('/scanner/scan')) {
    activeNavItem = 'scanner';
  } else if (location.pathname.startsWith('/scanner/profile')) {
    activeNavItem = 'profile';
  } else {
    activeNavItem = 'home';
  }

  return (
    <footer className="flex justify-center bg-white px-6 py-3 shadow-2xl">
      <div className="flex w-full max-w-lg flex-row items-center justify-center gap-8">
        <Link
          to="/scanner"
          className={cn(
            'flex flex-1 cursor-pointer flex-col items-center text-sm transition-colors',
            activeNavItem === 'home' ? 'text-blue-600' : 'text-gray-400'
          )}
        >
          <HomeIcon className={cn('transition-colors', activeNavItem === 'home' ? 'fill-blue-600' : 'fill-gray-400')} />
          <Text>Home</Text>
        </Link>

        <Link
          to="/scanner/scan"
          className={cn(
            'flex flex-1 cursor-pointer flex-col items-center text-sm transition-colors',
            activeNavItem === 'scanner' ? 'text-blue-600' : 'text-gray-400'
          )}
        >
          <ScannerIcon
            className={cn('transition-colors', activeNavItem === 'scanner' ? 'fill-blue-600' : 'fill-gray-400')}
          />
          <Text>Scanner</Text>
        </Link>

        {/* <Link
          to="/scanner/profile"
          className={cn(
            'flex flex-1 cursor-pointer flex-col items-center text-sm transition-colors',
            activeNavItem === 'profile' ? 'text-blue-600' : 'text-gray-400'
          )}
        >
          <ProfileIcon
            name={sessionStore.user!.name}
            className={cn('transition-colors', activeNavItem === 'profile' ? 'bg-blue-600' : 'bg-gray-400')}
          />
          <Text>Profile</Text>
        </Link> */}
      </div>
    </footer>
  );
}

function ScannerIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      id="icon-scanner"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
    >
      <rect id="box" width="24" height="24" fill="rgba(68,112,134,0)" />
      <path
        id="icon"
        d="M21.906,6.117a3.547,3.547,0,0,0-3.548-3.548H8.677a.966.966,0,0,0-.966.965V8.688a.966.966,0,0,0,.966.965h1.538A2.535,2.535,0,0,1,12.652,11.6c.6,2.019,2.019,6.758,2.382,7.9s1.542,2.483,3.874,1.718,2.583-2.219,2.081-3.95-1.3-4.276-1.592-5.379a2.689,2.689,0,0,1,1.032-2.9A3.4,3.4,0,0,0,21.9,6.115m-9.933,6.227a.875.875,0,0,0-.7.857V15.87a.875.875,0,0,0,.875.875H13.29ZM3.027,9.5a.37.37,0,0,0,.274.137.694.694,0,0,0,.12-.017.323.323,0,0,0,.23-.308.376.376,0,0,0-.12-.308,3.7,3.7,0,0,1-.848-1.335,4.184,4.184,0,0,1,.83-4.439A.431.431,0,0,0,3.659,2.9a.349.349,0,0,0-.6-.222,5.089,5.089,0,0,0-.693.907,5.1,5.1,0,0,0-.684,2.608A4.906,4.906,0,0,0,3.027,9.5M3.3,6.2A3.549,3.549,0,0,0,4.293,8.6a.363.363,0,0,0,.257.12A.343.343,0,0,0,4.7,8.684a.324.324,0,0,0,.188-.368.53.53,0,0,0-.12-.24A2.8,2.8,0,0,1,4.045,6.57a2.865,2.865,0,0,1,.736-2.438.563.563,0,0,0,.12-.248.334.334,0,0,0-.2-.359.341.341,0,0,0-.4.077,3.584,3.584,0,0,0-.8,1.317A3.738,3.738,0,0,0,3.3,6.2M5.773,7.785a.3.3,0,0,0,.12-.026.332.332,0,0,0,.23-.3.366.366,0,0,0-.111-.316,1.537,1.537,0,0,1,0-2.079.382.382,0,0,0,.12-.333.354.354,0,0,0-.24-.291.334.334,0,0,0-.359.094A2.24,2.24,0,0,0,4.96,5.586a3.13,3.13,0,0,0-.051.334v.018c0,.026-.009.051-.009.069a2.3,2.3,0,0,0,.625,1.65.342.342,0,0,0,.249.127"
        transform="translate(0.206 -0.004)"
      />
    </svg>
  );
}

function ProfileIcon({ name, className = '' }: { name: string; className?: string }) {
  return (
    <div className={cn('flex h-6 w-6 items-center justify-center rounded-full bg-gray-400', className)}>
      <span className="text-white">{name[0]}</span>
    </div>
  );
}
