import { IconButton } from '@ui/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Text } from '@ui/Text';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../utils';
import { useState } from 'react';

const IndicatorStyles = cva(
  'absolute top-[1px] h-[5px] w-[calc(100%-96px)] ml-[96px] [clip-path:polygon(0%_0%,100%_0%,100%_5px,5px_5px)] rounded-tr-sm',
  {
    variants: {
      variant: {
        neutral: 'bg-gray-700',
        add: 'bg-communication-add',
      },
    },
    defaultVariants: {
      variant: 'neutral',
    },
  }
);
const HeadlineStyles = cva('', {
  variants: {
    variant: {
      neutral: 'text-onSurface-highEmphasis',
      add: 'text-communication-add',
    },
  },
  defaultVariants: {
    variant: 'neutral',
  },
});

type Props = {
  onClose: () => void;
  HeaderIcon: React.ReactNode;
  headline: string;
  collapsible?: boolean;
  className?: string;
} & React.ComponentProps<'div'> &
  VariantProps<typeof IndicatorStyles> &
  VariantProps<typeof HeadlineStyles>;

export function Sidesheet({
  children,
  className,
  HeaderIcon,
  headline,
  collapsible = false,
  onClose,
  variant,
  ...props
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <div className={cn('relative w-full max-w-[360px]', className)} {...props}>
      <div className="h-full bg-gray-700 drop-shadow-[0px_3px_3px_rgba(0,0,0,0.32)] filter [clip-path:polygon(0%_0%,92px_0%,100px_8px,100%_8px,100%_100%,0%_100%)]">
        <div className="mx-[1px] my-[1px] flex h-full flex-col bg-white [clip-path:polygon(0%_1px,90px_1px,98px_9px,100%_9px,100%_calc(100%-1px),0%_calc(100%-1px))]">
          <div className="flex items-center justify-between gap-2 px-2 pb-2 pt-3">
            <div className="flex items-center gap-3 pl-2">
              {HeaderIcon}
              <Text variant="overline" className={cn(HeadlineStyles({ variant }))}>
                {headline}
              </Text>
            </div>

            <div className="flex items-center gap-1">
              {collapsible &&
                (isOpen ? (
                  <IconButton onClick={() => setIsOpen(false)}>
                    <ExpandLessIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setIsOpen(true)}>
                    <ExpandMoreIcon />
                  </IconButton>
                ))}

              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          {isOpen && children}
        </div>
      </div>

      <div className={cn(IndicatorStyles({ variant }))} />
      <div className="absolute left-[-1.5px] top-[-1px] h-2 w-2 border-l-[3px] border-t-[3px] border-gray-700" />
      <div className="absolute bottom-[-2.5px] left-[-1.5px] h-2 w-2 border-b-[3px] border-l-[3px] border-gray-700" />
      <div className="absolute bottom-[-2.5px] right-[-1.5px] h-2 w-2 border-b-[3px] border-r-[3px] border-gray-700" />
    </div>
  );
}
