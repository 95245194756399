import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import slLogoWhite from '@assets/sl-logo-white.svg';
import { z } from 'zod';
import { Input } from '@ui/Input';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSessionStore } from '@stores/sessionStore';
import { useApi } from '@hooks/useApi';
import { Button } from '@ui/Button';

const formSchema = z.object({
  email: z.string().email('Bitte geben Sie eine gültige E-Mail Adresse ein.'),
  password: z.string(),
});

export function Login() {
  const api = useApi();
  const sessionStore = useSessionStore();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({ resolver: zodResolver(formSchema) });

  const loginMutation = useMutation({
    mutationFn: api.auth.login,
  });

  const handleSubmit = form.handleSubmit((data) => {
    loginMutation.mutate(
      { data: { email: data.email, password: data.password } },
      {
        onSuccess: (res) => {
          if (!res.ok) {
            switch (res.data.errCode) {
              case 'user_not_found': {
                form.setError('email', { message: 'Unbekannte E-Mail Adresse.' });
                return;
              }
              case 'too_many_login_attempts': {
                form.setError('email', { message: 'Zu viele Loginversuche. Versuchen Sie es später erneut.' });
                return;
              }
              case 'password_incorrect': {
                form.setError('password', { message: 'Das Passwort ist nicht korrekt.' });
                return;
              }
              default: {
                const _exhaustiveCheck: never = res.data.errCode;
                return;
              }
            }
          }

          localStorage.setItem('session_token', res.data.sessionToken);
          sessionStore.setSession({ sessionToken: res.data.sessionToken });
          navigate('/scanner');
        },
      }
    );
  });

  return (
    <div className="flex min-h-screen w-full items-center justify-center bg-gradient-to-tl from-[#8CAD51] to-[#547E7A]">
      <div className="m-4 flex w-full max-w-md flex-col items-center">
        <img src={slLogoWhite} alt="SupplyLine Logo" className="mb-10 h-10" />

        <form onSubmit={handleSubmit} className="w-full rounded bg-white px-6 py-8">
          <h1 className="text-2xl font-medium">Willkommen</h1>

          <div className="mt-10 flex flex-col gap-4">
            <Input
              type="email"
              label="E-Mail Adresse"
              autoComplete="username"
              required
              errorMessage={form.formState.errors.email?.message}
              {...form.register('email')}
            />

            <Input
              type="password"
              label="Passwort"
              autoComplete="current-password"
              required
              errorMessage={form.formState.errors.password?.message}
              {...form.register('password')}
            />
          </div>

          <div className="mt-8">
            <Button type="submit" disabled={loginMutation.isLoading} fullWidth>
              Login
            </Button>
          </div>
        </form>

        <div className="mt-3 flex flex-row gap-8 self-start px-6">
          <a
            href="https://asstroemungstechnik.de/de/legal/impressum"
            target="_blank"
            rel="noopener"
            className="text-white"
          >
            Impressum
          </a>
          <a
            href="https://supplyline.as/datenschutzerklaerung.pdf"
            target="_blank"
            rel="noopener"
            className="text-white"
          >
            Datenschutz
          </a>
          <a href="https://supplyline.as/de/contact" target="_blank" rel="noopener" className="text-white">
            Kontakt
          </a>
        </div>
      </div>
    </div>
  );
}
