import rfidIcon from '@assets/icons/icon-rfid.svg';
import { zodResolver } from '@hookform/resolvers/zod';
import { useApi } from '@hooks/useApi';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@ui/Button';
import { Input } from '@ui/Input';
import { Text } from '@ui/Text';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  rfidTagUID: z.string().length(16, 'Die RFID Tag UID muss genau 16 Zeichen lang sein.'),
});

type Props = {
  setContainerId: (id: string) => void;
  isOpen: boolean;
  onOpen: () => void;
};

export function SensorCard({ setContainerId, isOpen, onOpen }: Props) {
  const api = useApi();

  const form = useForm<z.infer<typeof formSchema>>({ resolver: zodResolver(formSchema) });

  const getRfidCarrierByTagUIDMutation = useMutation({
    mutationFn: api.rfidCarriers.getRfidCarrierByRfidTagUID,
  });

  const handleSubmit = form.handleSubmit((data) => {
    getRfidCarrierByTagUIDMutation.mutate(
      { rfidTagUID: data.rfidTagUID },
      {
        onSuccess: (res) => {
          if (!res.ok) {
            switch (res.data.errCode) {
              case 'rfid_carrier_not_found': {
                form.setError('rfidTagUID', { message: 'Unbekannter Sensor.' });
                return;
              }
              default: {
                const _exhaustiveCheck: never = res.data.errCode;
                return;
              }
            }
          }

          if (res.data.containerId === null) {
            form.setError('rfidTagUID', { message: 'Dieser Sensor ist in keinen Container eingebaut.' });
            return;
          }

          form.reset();
          setContainerId(res.data.containerId);
        },
      }
    );
  });

  return (
    <div className="rounded bg-white shadow hover:shadow-lg">
      <form onSubmit={handleSubmit}>
        <button type="button" onClick={onOpen} className="flex w-full items-center gap-7 px-5 py-4" disabled={isOpen}>
          <img src={rfidIcon} alt="" />
          <p>
            <Text variant="heading2">SupplyLine Cap</Text>
          </p>
        </button>

        {isOpen && (
          <div className="flex flex-col gap-6 px-5 py-4">
            <Input
              type="text"
              label="RFID-Chip"
              required
              errorMessage={form.formState.errors.rfidTagUID?.message}
              autoFocus
              {...form.register('rfidTagUID')}
            />

            <div className="grid grid-cols-2">
              <Button fullWidth disabled={getRfidCarrierByTagUIDMutation.isLoading} className="col-start-2">
                Scan
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
