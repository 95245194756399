import { Map, MapRef, Marker } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import CONFIG from '../../../../config';
import { Sidesheet } from '@ui/Sidesheet';
import { Button } from '@ui/Button';
import { cn } from '../../../../utils';
import iconContainerTankDark from '@assets/icons/24px/icon-container-tank-dark-24px.svg';
import iconIBCContainerDark from '@assets/icons/24px/icon-container-ibc-dark-24px.svg';
import { useMapStyleStore } from '@stores/mapStyleStore';
import { useEffect, useRef, useState } from 'react';
import { MarkerPin } from '@ui/MarkerPin';

type Props = {
  container: {
    type: 'ibc' | 'tank_container';
    serial: string;
    location: {
      lng: number;
      lat: number;
    };
  };
  onClose: () => void;
};

const markerIcons = {
  ibc: iconIBCContainerDark,
  tank_container: iconContainerTankDark,
};

export function ContainersMap({ container, onClose }: Props) {
  const mapStyleStore = useMapStyleStore();

  const mapRef = useRef<MapRef>(null);

  const [isInitialized, setIsInitialized] = useState(false);
  const [viewState, setViewState] = useState({
    longitude: container.location.lng,
    latitude: container.location.lat,
    zoom: 15,
  });

  useEffect(() => {
    if (!mapRef.current) return;
    if (!isInitialized) return;
    if (!container) return;

    mapRef.current.flyTo({
      center: [container.location.lng, container.location.lat],
      zoom: 15,
      duration: 2000,
      essential: true,
    });
  }, [isInitialized, container]);

  const containerIcon = markerIcons[container.type];

  return (
    <div className="relative flex h-full">
      <div className="absolute right-0 top-0 z-20 flex max-h-full p-5 transition-opacity lg:opacity-60 lg:hover:opacity-100">
        <Sidesheet
          variant={'neutral'}
          HeaderIcon={<img src={containerIcon} alt="Container" />}
          headline={container.serial}
          onClose={onClose}
        />
      </div>

      <div className="absolute bottom-5 left-5 z-50 h-12 rounded-sm bg-white shadow-md">
        <div className="flex h-full items-center justify-center">
          <div className="flex w-full">
            {mapStyleStore.availableStyles.map((mapStyle) => {
              const isActive = mapStyle.target === mapStyleStore.selectedStyle;

              return (
                <Button
                  key={mapStyle.target}
                  variant="tabInactive"
                  onClick={() => mapStyleStore.setStyle(mapStyle.target)}
                  className={cn('h-12 text-onSurface-mediumEmphasis', isActive && 'text-onSurface-highEmphasis')}
                >
                  {mapStyle.label}
                </Button>
              );
            })}
          </div>
        </div>
      </div>

      <Map
        onLoad={() => setIsInitialized(true)}
        onRemove={() => setIsInitialized(false)}
        ref={mapRef}
        {...viewState}
        onMove={(e) => setViewState(e.viewState)}
        mapLib={mapboxgl}
        mapboxAccessToken={CONFIG.MAPBOX_ACCESS_TOKEN}
        mapStyle={mapStyleStore.selectedStyle}
      >
        {isInitialized && (
          <>
            <Marker longitude={container.location.lng} latitude={container.location.lat}>
              <MarkerPin containerType={container.type} />
            </Marker>
          </>
        )}
      </Map>
    </div>
  );
}
