import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';
import { CapNotFound } from './CapNotFound';
import { CapNoData } from './CapNoData';
import { CapDetails } from './CapDetails';
import { PublicHeader } from '@components/scanner/PublicHeader';
import iconCap from '@assets/icons/40px/icon-cap-bright-40px.svg';

export function Cap() {
  const api = useApi();
  const params = useParams<{ serial: string }>();

  if (!params.serial) {
    return <Navigate to="/" />;
  }

  const capQuery = useQuery({
    queryKey: ['rfidCarriers', 'public', params.serial],
    queryFn: () => api.public.getRFIDCarrierBySerial({ serial: params.serial! }),
  });

  if (capQuery.isLoading) {
    return <p>Loading...</p>; // TODO better loading | 19.02.2024 jl
  }

  const cap = capQuery.data?.ok ? capQuery.data.data : null;

  return (
    <div className="h-screen w-full bg-surface-dark xl:flex xl:justify-center xl:pt-16">
      <div className="h-full bg-surface-bright xl:h-[900px] xl:w-[1024px]">
        <div className="flex h-full flex-col justify-between">
          <PublicHeader title="Cap Details" Icon={<img src={iconCap} alt="" />} />

          <main className="h-full overflow-y-auto pb-14 pt-5">
            {!cap && <CapNotFound serial={params.serial!} />}

            {cap && (!cap.container || !cap.container.temperature) && <CapNoData />}

            {cap && cap.container && cap.container.temperature && (
              <CapDetails cap={{ ...cap, container: cap.container }} />
            )}
          </main>
        </div>
      </div>
    </div>
  );
}
