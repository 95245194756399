import { useState } from 'react';
import iconTankContainerBright from '@assets/icons/40px/icon-container-tank-bright-40px.svg';
import iconIbcBright from '@assets/icons/40px/icon-container-ibc-bright-40px.svg';
import SettingsIcon from '@mui/icons-material/Settings';
import SensorsIcon from '@mui/icons-material/Sensors';
import { DetailsHeader } from '../DetailsHeader';
import { GeneralTab } from './GeneralTab';
import { SensorsTab } from './SensorsTab';

type Props = {
  container: {
    id: string;
    serial: string;
    type: 'ibc' | 'tank_container';
    lifetimeExpiresAt: Date | null;
    nextInspectionType: 'major' | 'minor' | null;
    minTemperature: number | null;
    maxTemperature: number | null;
    chemical: {
      id: string;
      name: string;
    } | null;
    rfidCarriers: {
      id: string;
    }[];
    location: {
      address: string | null;
      lng: number;
      lat: number;
      accuracy: number;
      countryCode: string | null;
    } | null;
    lastSeenAt: Date | null;
  };
  onClose: () => void;
};

export function ContainerDetails({ container, onClose }: Props) {
  const [activeTab, setActiveTab] = useState<'general' | 'sensors'>('general');

  return (
    <>
      <DetailsHeader
        title="Container Details"
        Icon={
          container.type === 'ibc' ? (
            <img src={iconIbcBright} alt="" className="h-10" />
          ) : (
            <img src={iconTankContainerBright} alt="" className="h-10" />
          )
        }
        onClose={onClose}
        tabs={[
          {
            Icon: <SettingsIcon />,
            label: 'Allgemein',
            onClick: () => setActiveTab('general'),
            isActive: activeTab === 'general',
          },
          {
            Icon: <SensorsIcon />,
            label: 'Sensoren',
            onClick: () => setActiveTab('sensors'),
            isActive: activeTab === 'sensors',
          },
        ]}
      />

      <main className="h-full overflow-y-auto pb-14 pt-5">
        {activeTab === 'general' && <GeneralTab container={container} onCancel={onClose} />}

        {activeTab === 'sensors' && <SensorsTab container={container} />}
      </main>
    </>
  );
}
