import iconNoSensor from '@assets/icons/24px/icon-cap-warning-dark-24px.svg';
import { DataWrapper } from '@ui/DataWrapper';
import { Text } from '@ui/Text';

type Props = {
  container: {
    rfidCarriers: {}[];
    minTemperature: number | null;
    maxTemperature: number | null;
  };
};

export function SensorsTab({ container }: Props) {
  return (
    <div>
      {container.rfidCarriers.length === 0 && (
        <div className="mb-5 px-6">
          <div className="flex items-center rounded bg-communication-warning py-3 pl-4">
            <img src={iconNoSensor} alt="Container" className="h-6" />
            <div className="pl-4">
              <Text variant={'heading3'}>Aktuell kein Sensor installiert</Text>
              <Text variant={'body1'}></Text>
            </div>
          </div>
        </div>
      )}

      <div className=" bg-white px-6 pb-6 pt-2">
        <div className="flex flex-col gap-[10px]">
          <Text variant={'heading3'}>Temperatur Sensor</Text>
          {container.minTemperature && <DataWrapper label={'Min Temperatur'} value={`${container.minTemperature}°C`} />}
          {container.maxTemperature && <DataWrapper label={'Max Temperatur'} value={`${container.maxTemperature}°C`} />}
        </div>
      </div>

      {/* <div className="mt-5 bg-white px-6 pb-6 pt-2">
        <div className="grid grid-cols-1 gap-[10px]">
          <Text variant={'heading3'}>Schock Sensor</Text>
          <Text variant={'body1'}>Vibrationen werden ab einer Stärke von ca. 2G erkannt.</Text>
        </div>
      </div> */}
    </div>
  );
}
