import InfoIcon from '@mui/icons-material/Info';
import illuLine from '@assets/icons/illu-line.svg';
import iconContainerDark from '@assets/icons/24px/icon-container-dark-24px.svg';
import { Text } from '@ui/Text';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../utils';
import { BatteryIcon } from './BatteryIcon';
import { SvgIcon } from '@mui/material';

const IndicatorStyles = cva(
  'absolute top-[1px] h-[5px] w-[calc(100%-96px)] ml-[96px] [clip-path:polygon(0%_0%,100%_0%,100%_5px,5px_5px)] rounded-tr-sm',
  {
    variants: {
      variant: {
        neutral: 'bg-gray-700',
        danger: 'bg-communication-warning',
      },
    },
    defaultVariants: {
      variant: 'neutral',
    },
  }
);
const IconStyles = cva('', {
  variants: {
    variant: {
      neutral: 'invisible',
      danger: 'visible',
    },
  },
  defaultVariants: {
    variant: 'neutral',
  },
});

type Props = {
  headline: string;
  warningTemp?: boolean;
  warningShock?: boolean;
  className?: string;
} & React.ComponentProps<'div'> &
  VariantProps<typeof IndicatorStyles>;

export function ContainerMarker({ children, headline, onClick, variant, warningTemp, warningShock, ...props }: Props) {
  return (
    <div className="group relative cursor-pointer">
      <div className="flex h-12 w-12 items-center justify-center rounded-full border-[1px] border-solid border-gray-700">
        <div className="flex h-[42px] w-[42px] items-center justify-center rounded-full border-[1px] border-solid border-gray-700 bg-[#ffffffcc]">
          <img src={iconContainerDark} alt="Container" />
        </div>
      </div>

      <div className="absolute bottom-[42px] left-[89px] w-[270px]">
        <div className="relative w-full" {...props}>
          <div className="h-full w-full bg-gray-700 drop-shadow-[0px_3px_3px_rgba(0,0,0,0.32)] filter [clip-path:polygon(0%_0%,92px_0%,100px_8px,100%_8px,100%_100%,0%_100%)]">
            <div className="mx-[1px] my-[1px] h-full bg-surface-bright [clip-path:polygon(0%_1px,90px_1px,98px_9px,100%_9px,100%_calc(100%-1px),0%_calc(100%-1px))] group-hover:bg-surface-dark">
              <div className="flex justify-between px-3 pb-3 pt-4">
                <div className="flex items-center gap-3">
                  {<InfoIcon />}
                  <Text variant="overline">{headline}</Text>
                </div>
                <div className="flex gap-1">
                  <SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="icon-warning-temp" transform="translate(-944.927 -379.5)">
                        <path
                          id="device_thermostat"
                          d="M285-860a4.819,4.819,0,0,1-3.537-1.463A4.819,4.819,0,0,1,280-865a4.885,4.885,0,0,1,.525-2.237A5.084,5.084,0,0,1,282-869v-8a2.893,2.893,0,0,1,.875-2.125A2.893,2.893,0,0,1,285-880a2.893,2.893,0,0,1,2.125.875A2.893,2.893,0,0,1,288-877v8a5.083,5.083,0,0,1,1.475,1.763A4.885,4.885,0,0,1,290-865a4.819,4.819,0,0,1-1.462,3.537A4.819,4.819,0,0,1,285-860Zm-1-11h2v-1h-1v-1h1v-2h-1v-1h1v-1a.968.968,0,0,0-.288-.713A.968.968,0,0,0,285-878a.968.968,0,0,0-.713.287A.968.968,0,0,0,284-877Z"
                          transform="translate(671.943 1261.531)"
                          fill={warningTemp ? '#FAC05E' : '#DADADA'}
                        />
                      </g>
                    </svg>
                  </SvgIcon>
                  <SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="icon-warning-shock" transform="translate(-944.927 -379.5)">
                        <path
                          id="earthquake"
                          d="M87.025-860a1.027,1.027,0,0,1-.613-.187.956.956,0,0,1-.362-.512L83.5-869H80v-2h4.25a.987.987,0,0,1,.587.188.954.954,0,0,1,.362.512l1.65,5.375L90.025-879.2a.99.99,0,0,1,.35-.575A.96.96,0,0,1,91-880a1,1,0,0,1,.625.213.935.935,0,0,1,.35.562l2.175,9.4,1.4-4.475a.955.955,0,0,1,.363-.512A.987.987,0,0,1,96.5-875a.978.978,0,0,1,.575.175,1.064,1.064,0,0,1,.375.475L98.7-871H100v2H98a.977.977,0,0,1-.575-.175,1.064,1.064,0,0,1-.375-.475l-.475-1.275L94.95-865.7a1,1,0,0,1-.375.525.919.919,0,0,1-.625.175,1.011,1.011,0,0,1-.6-.237.974.974,0,0,1-.325-.538L91-874.475l-3.025,13.7a.9.9,0,0,1-.337.55A1.118,1.118,0,0,1,87.025-860Z"
                          transform="translate(866.927 1261.459)"
                          fill={warningShock ? '#FAC05E' : '#DADADA'}
                        />
                      </g>
                    </svg>
                  </SvgIcon>
                  <BatteryIcon />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={cn(IndicatorStyles({ variant }))} />
        <div className="absolute left-[-1.5px] top-[-1px] h-2 w-2 border-l-[3px] border-t-[3px] border-gray-700 group-hover:left-[-3.5px] group-hover:top-[-3px]" />
        <div className="absolute bottom-[-1.5px] left-[-1.5px] h-2 w-2 border-b-[3px] border-l-[3px] border-gray-700 group-hover:bottom-[-3.5px] group-hover:left-[-3.5px]" />
        <div className="absolute bottom-[-1.5px] right-[-1.5px] h-2 w-2 border-b-[3px] border-r-[3px] border-gray-700 group-hover:bottom-[-3.5px] group-hover:right-[-3.5px]" />
      </div>

      <div className="absolute bottom-[38px] left-[39px] z-50 h-[31px] w-[50px]">
        <img src={illuLine} />
      </div>
    </div>
  );
}
