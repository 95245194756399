import { Button } from '@ui/Button';
import { Text } from '@ui/Text';
import AddIcon from '@mui/icons-material/Add';
import iconContainerDark from '@assets/icons/24px/icon-container-dark-24px.svg';
import iconAddZone from '@assets/icons/icon-new-zone.svg';
import { Notification } from '@ui/Notification';
import { notify } from '../notify';
import { Sidesheet } from '@ui/Sidesheet';
import { DataWrapper } from '@ui/DataWrapper';
import { ContainerMarker } from '@ui/ContainerMarker';
import { BatteryIcon } from '@ui/BatteryIcon';
import { Input } from '@ui/Input';
import { ContainerMarkerCluster } from '@ui/ContainerMarkerCluster';
import { useNavigate } from 'react-router-dom';
import { InputDropdown } from '@ui/InputDropdown';

export function Designer() {
  const navigate = useNavigate();

  function triggerRandomNotification() {
    const types = ['info', 'success', 'warning', 'error'] as const;
    const type = types[Math.floor(Math.random() * types.length)];

    notify({
      type: type,
      message: 'Random Notification',
    });
  }

  return (
    <div className="p-8">
      <button onClick={() => navigate('/')} className="rounded px-2.5 py-1 transition-colors hover:bg-gray-100">
        &larr; Designer verlassen
      </button>
      <div className="mt-6 grid grid-cols-1 gap-x-12 gap-y-20 sm:grid-cols-2 lg:grid-cols-3">
        <section>
          <div>
            <Text variant="heading1">Theme</Text>
          </div>
          <div className="mt-2">
            <Text variant="heading2">Primary Palette</Text>
          </div>
          <div>
            <div className="h-5 bg-blue-900" />
            <div className="h-5 bg-blue-800" />
            <div className="h-5 bg-blue-700" />
            <div className="h-5 bg-blue-600" />
            <div className="h-5 bg-blue-500" />
            <div className="h-5 bg-blue-400" />
            <div className="h-5 bg-blue-300" />
            <div className="h-5 bg-blue-200" />
            <div className="h-5 bg-blue-100" />
            <div className="h-5 bg-blue-50" />
          </div>
          <div className="mt-2">
            <Text variant="heading2">Secondary Palette</Text>
          </div>
          <div>
            <div className="h-5 bg-green-900" />
            <div className="h-5 bg-green-800" />
            <div className="h-5 bg-green-700" />
            <div className="h-5 bg-green-600" />
            <div className="h-5 bg-green-500" />
            <div className="h-5 bg-green-400" />
            <div className="h-5 bg-green-300" />
            <div className="h-5 bg-green-200" />
            <div className="h-5 bg-green-100" />
            <div className="h-5 bg-green-50" />
          </div>
          <div className="mt-2">
            <Text variant="heading2">Gray Palette</Text>
          </div>
          <div>
            <div className="h-5 bg-gray-900" />
            <div className="h-5 bg-gray-800" />
            <div className="h-5 bg-gray-700" />
            <div className="h-5 bg-gray-600" />
            <div className="h-5 bg-gray-500" />
            <div className="h-5 bg-gray-400" />
            <div className="h-5 bg-gray-300" />
            <div className="h-5 bg-gray-200" />
            <div className="h-5 bg-gray-100" />
            <div className="h-5 bg-gray-50" />
          </div>
        </section>

        <section>
          <div>
            <div className="mt-9">
              <Text variant="heading2">Communication Colors</Text>
            </div>
            <div className="flex h-8 items-center bg-communication-success">
              <Text variant="body1" className=" ml-1 text-white">
                success
              </Text>
            </div>
            <div className="flex h-8 items-center bg-communication-warning">
              <Text variant="body1" className=" ml-1 text-onSurface-highEmphasis">
                warning
              </Text>
            </div>
            <div className="flex h-8 items-center bg-communication-error">
              <Text variant="body1" className=" ml-1 text-white">
                error
              </Text>
            </div>
            <div className="flex h-8 items-center bg-communication-add">
              <Text variant="body1" className=" ml-1 text-white">
                add
              </Text>
            </div>
          </div>
          <div>
            <div className="mt-2">
              <Text variant="heading2">Surface Colors</Text>
            </div>
            <div className="flex h-8 items-center bg-surface-bright">
              <Text variant="body1" className=" ml-1">
                bright
              </Text>
            </div>
            <div className="flex h-8 items-center bg-surface-dark">
              <Text variant="body1" className=" ml-1">
                dark
              </Text>
            </div>
            <div className="mt-2">
              <Text variant="heading2">Text on Surface Colors</Text>
            </div>
            <div className="flex h-8 items-center bg-onSurface-highEmphasis">
              <Text variant="body1" className=" ml-1 text-white">
                highEmphasis
              </Text>
            </div>
            <div className="flex h-8 items-center bg-onSurface-mediumEmphasis">
              <Text variant="body1" className=" ml-1 text-white">
                mediumEmphasis
              </Text>
            </div>
            <div className="flex h-8 items-center bg-onSurface-lowEmphasis">
              <Text variant="body1" className=" ml-1">
                lowEmphasis
              </Text>
            </div>
          </div>
        </section>

        <section>
          <h1 className="text-xl font-medium">Text</h1>
          <div className="mt-8">
            <div className="flex flex-col items-start gap-3">
              <Text variant="heading1">Heading 1</Text>

              <Text variant="heading2">Heading 2</Text>

              <Text variant="heading3">Heading 3</Text>

              <Text variant="heading4">Heading 4</Text>

              <Text variant="body1">Body 1</Text>

              <Text variant="body2">Body 2</Text>

              <Text variant="subtitle1">Subtitle 1</Text>

              <Text variant="subtitle2">Subtitle 2</Text>

              <Text variant="caption">Caption</Text>

              <Text variant="overline">Overline</Text>
            </div>
          </div>
        </section>

        <section>
          <h1 className="text-xl font-medium">Button Contained</h1>
          <div className="mt-8">
            <div className="flex flex-col items-start gap-3">
              <Button variant="contained">Text Label</Button>

              <Button variant="contained" disabled>
                Text Label
              </Button>
              <Button variant="containedWarning">Text Label</Button>

              <Button variant="contained" LeadingIcon={<AddIcon />}>
                Text Label
              </Button>

              <Button variant="contained" disabled LeadingIcon={<AddIcon />}>
                Text Label
              </Button>
              <Button variant="containedWarning" LeadingIcon={<AddIcon />}>
                Text Label
              </Button>
            </div>
          </div>
        </section>

        <section>
          <h1 className="text-xl font-medium">Button Outlined</h1>
          <div className="mt-8">
            <div className="flex flex-col items-start gap-3">
              <Button variant="outlined">Text Label</Button>

              <Button variant="outlined" disabled>
                Text Label
              </Button>

              <Button variant="outlined" LeadingIcon={<AddIcon />}>
                Text Label
              </Button>

              <Button variant="outlined" disabled LeadingIcon={<AddIcon />}>
                Text Label
              </Button>
            </div>
          </div>
        </section>
        <section>
          <h1 className="text-xl font-medium">Button Text</h1>
          <div className="mt-8">
            <div className="flex flex-col items-start gap-3">
              <Button variant="text">Text Label</Button>

              <Button variant="text" disabled>
                Text Label
              </Button>

              <Button variant="text" LeadingIcon={<AddIcon />}>
                Text Label
              </Button>

              <Button variant="text" disabled LeadingIcon={<AddIcon />}>
                Text Label
              </Button>
              <Button variant="textDanger" LeadingIcon={<AddIcon />}>
                Text Label
              </Button>
              <Button variant="textAdd" LeadingIcon={<AddIcon />}>
                Text Label
              </Button>
            </div>
          </div>
        </section>

        <section>
          <h1 className="text-xl font-medium">Textfield Outlined</h1>
          <div className="mt-8 grid grid-cols-1 gap-[10px]">
            <Input type="text" label="E-Mail" />
            <Input type="password" label="Password" />
            <Input type="text" label="Disabled" disabled />
            <Input type="text" label="Label" helperText="With Helper Text" />
            <Input type="text" label="Label" errorMessage="With Error Message" helperText="With Helper Text" />
            <Input type="text" label="Leading and Trailing Icon" LeadingIcon={<AddIcon />} TrailingIcon={<AddIcon />} />
          </div>
        </section>

        <section>
          <h1 className="text-xl font-medium">Textfield Contained</h1>
          <div className="mt-8 grid grid-cols-1 gap-[10px]">
            <Input variant={'contained'} type="text" label="E-Mail" />
            <Input variant={'contained'} type="password" label="Password" />
            <Input variant={'contained'} type="text" label="Disabled" disabled />
            <Input variant={'contained'} type="text" label="Label" helperText="With Helper Text" />
            <Input
              variant={'contained'}
              type="text"
              label="Label"
              errorMessage="With Error Message"
              helperText="With Helper Text"
            />
            <Input
              variant={'contained'}
              type="text"
              label="Leading and Trailing Icon"
              LeadingIcon={<AddIcon />}
              TrailingIcon={<AddIcon />}
            />
          </div>
        </section>

        <section>
          <h1 className="text-xl font-medium">Dropdown Contained</h1>
          <div className="mt-8 grid grid-cols-1 gap-[10px]">
            <InputDropdown
              label="Label"
              options={[
                { key: 'Option 1', value: 'option1' },
                { key: 'Option 2', value: 'option2' },
                { key: 'Option 3', value: 'option3' },
              ]}
              variant={'contained'}
            />

            <InputDropdown
              label="Label"
              options={[
                { key: 'Option 1', value: 'option1' },
                { key: 'Option 2', value: 'option2' },
                { key: 'Option 3', value: 'option3' },
              ]}
              variant={'contained'}
              disabled
            />
            <InputDropdown
              label="Label"
              options={[
                { key: 'Option 1', value: 'option1' },
                { key: 'Option 2', value: 'option2' },
                { key: 'Option 3', value: 'option3' },
              ]}
              variant={'contained'}
              helperText="With Helper Text"
            />
            <InputDropdown
              label="Label"
              options={[
                { key: 'Option 1', value: 'option1' },
                { key: 'Option 2', value: 'option2' },
                { key: 'Option 3', value: 'option3' },
              ]}
              variant={'contained'}
              errorMessage="With Error Message"
              helperText="With Helper Text"
            />
            <InputDropdown
              label="Label"
              options={[
                { key: 'Option 1', value: 'option1' },
                { key: 'Option 2', value: 'option2' },
                { key: 'Option 3', value: 'option3' },
              ]}
              variant={'contained'}
              LeadingIcon={<AddIcon />}
              TrailingIcon={<AddIcon />}
            />
          </div>
        </section>

        <section>
          <h1 className="text-xl font-medium">DataWrapper</h1>
          <div className="mt-8 grid grid-cols-1 gap-[10px]">
            <DataWrapper label="Label" value="Variant normal 0123" />
            <DataWrapper label="Label" variant={'mono'} value="Variant mono 0123" LeadingIcon={<AddIcon />} />
            <DataWrapper
              label="Label"
              value="Loooooooooooooooooooong Value Loooooooooooooooooooooooooooooooooooooooooong Value"
              LeadingIcon={<AddIcon />}
            />
            <DataWrapper
              label="Batteriestatus"
              variant={'mono'}
              value="74%"
              LeadingIcon={<BatteryIcon variant={'medium'} />}
            />
            <DataWrapper label="Lebenszeit" variant={'mono'} value="2020-02-20" isError />
          </div>
        </section>

        <section>
          <h1 className="text-xl font-medium">Notifications</h1>
          <div className="mt-8">
            <button
              onClick={triggerRandomNotification}
              className="rounded px-2.5 py-1 transition-colors hover:bg-gray-100"
            >
              Trigger Random Notification
            </button>
          </div>
          <div className="mt-8">
            <div className="flex flex-col items-start gap-3">
              <Notification variant="info" onClose={() => undefined}>
                Information text...
              </Notification>

              <Notification variant="success" onClose={() => undefined}>
                Success text...
              </Notification>

              <Notification variant="warning" onClose={() => undefined}>
                Warning text...
              </Notification>

              <Notification variant="error" onClose={() => undefined}>
                Error text...
              </Notification>
            </div>
          </div>
        </section>
        <section>
          <h1 className="text-xl font-medium">ContainerMarker</h1>
          <div className="mt-20 flex flex-col items-start gap-12">
            <ContainerMarker variant={'neutral'} headline="0FA12E442290" onClick={() => undefined} />
            <ContainerMarker
              variant={'danger'}
              warningTemp
              warningShock
              headline="0FA12E442290"
              onClick={() => undefined}
            />
          </div>
          <h1 className="mt-10 text-xl font-medium">ContainerMarkerCluster</h1>
          <div className="mt-20 flex flex-col items-start gap-12">
            <ContainerMarkerCluster variant={'neutral'} headline="13 Container" onClick={() => undefined} />
            <ContainerMarkerCluster variant={'danger'} headline="13 Container" onClick={() => undefined} />
          </div>
        </section>

        <section>
          <div className="h-[800px] bg-slate-200">
            <div className="flex max-h-full w-full justify-end px-4 py-4">
              <Sidesheet
                variant={'neutral'}
                HeaderIcon={<img src={iconContainerDark} alt="Container" />}
                headline="Container Details"
                onClose={() => undefined}
              >
                <div className="flex h-full flex-col gap-8 overflow-y-auto px-5 pb-20 pt-4">
                  <div className="grid grid-cols-1 gap-[10px]">
                    <Text variant={'heading3'}>Section 1</Text>
                    <DataWrapper label="Container S/N" value="123456ABC" />
                    <DataWrapper label="Label-1" value="Value" />
                  </div>

                  <div className="grid grid-cols-1 gap-[10px]">
                    <Text variant={'heading3'}>Section 2</Text>
                    <DataWrapper label="Container S/N" value="123456ABC" />
                    <DataWrapper label="Label-2" value="Value" />
                  </div>
                  <DataWrapper label="Label-2" value="Value" />
                  <DataWrapper label="Label-3" value="Value" />
                  <DataWrapper label="Label-4" value="Value" />
                  <DataWrapper label="Label-5" value="Value" />
                  <DataWrapper label="Label-6" value="Value" />
                  <DataWrapper label="Label-7" value="Value" />
                  <DataWrapper label="Label-8" value="Value" />
                  <DataWrapper label="Label-9" value="Value" />
                  <DataWrapper label="Label-10" value="Value" />
                </div>
              </Sidesheet>
            </div>
          </div>
        </section>

        <section>
          <div className="flex h-[800px] justify-end bg-slate-200 px-4 py-4">
            <Sidesheet
              variant={'add'}
              HeaderIcon={<img src={iconAddZone} alt="Container" />}
              headline="Container Details"
              onClose={() => undefined}
            >
              <div className="flex h-full flex-col gap-8 overflow-y-auto px-5 pb-20 pt-4">
                <div className="grid grid-cols-1 gap-[10px]">
                  <Text variant={'heading3'}>Section 1</Text>
                  <DataWrapper label="Container S/N" value="123456ABC" />
                  <DataWrapper label="Label-1" value="Value" />
                </div>

                <div className="grid grid-cols-1 gap-[10px]">
                  <Text variant={'heading3'}>Section 2</Text>
                  <DataWrapper label="Container S/N" value="123456ABC" />
                  <DataWrapper label="Label-2" value="Value" />
                </div>
                <DataWrapper label="Label-2" value="Value" />
                <DataWrapper label="Label-3" value="Value" />
                <DataWrapper label="Label-4" value="Value" />
                <DataWrapper label="Label-5" value="Value" />
                <DataWrapper label="Label-6" value="Value" />
                <DataWrapper label="Label-7" value="Value" />
                <DataWrapper label="Label-8" value="Value" />
                <DataWrapper label="Label-9" value="Value" />
                <DataWrapper label="Label-10" value="Value" />
              </div>
            </Sidesheet>
          </div>
        </section>
      </div>
    </div>
  );
}
