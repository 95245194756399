import { useState } from 'react';
import { DataWrapper } from '@ui/DataWrapper';
import { BatteryStatus } from '@components/BatteryStatus';
import SettingsIcon from '@mui/icons-material/Settings';
import iconCapBright from '@assets/icons/40px/icon-cap-bright-40px.svg';
import { DetailsHeader } from './DetailsHeader';

type Props = {
  rfidCarrier: {
    id: string;
    serial: string;
    rfidTagUID: string;
    batteryLevel: number | null;
  };

  onClose: () => void;
};

export function CapDetails({ rfidCarrier, onClose }: Props) {
  const [activeTab, setActiveTab] = useState<'general'>('general');

  return (
    <>
      <DetailsHeader
        title="Cap Details"
        Icon={<img src={iconCapBright} alt="" className="h-10" />}
        onClose={onClose}
        tabs={[
          {
            Icon: <SettingsIcon />,
            label: 'Allgemein',
            onClick: () => setActiveTab('general'),
            isActive: activeTab === 'general',
          },
        ]}
      />

      <main className="h-full overflow-y-auto pb-14 pt-5">
        <div className="px-6">
          <div className="flex flex-col gap-[10px]">
            <DataWrapper label="SupplyLine Cap S/N" value={rfidCarrier.serial} />
            <DataWrapper label="RFID-Chip" value={rfidCarrier.rfidTagUID} />
            <DataWrapper
              label="Akkustand"
              value={rfidCarrier.batteryLevel !== null ? `${rfidCarrier.batteryLevel.toString()}%` : '-'}
              LeadingIcon={
                <>
                  <BatteryStatus batteryLevel={rfidCarrier.batteryLevel} />
                </>
              }
            />
          </div>
        </div>
      </main>
    </>
  );
}
