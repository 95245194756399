import { Text } from '@ui/Text';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@ui/IconButton';

type Props = {
  onBack?: () => void;
  title: string;
};

export function Header({ title, onBack }: Props) {
  return (
    <header className=" bg-white shadow-md">
      {onBack ? (
        <div className="flex flex-row items-center gap-3 py-2 pl-[10px] pr-6">
          <IconButton className="h-12 w-12 p-0.5" onClick={() => onBack()}>
            <ArrowBackIcon sx={{ fontSize: 24 }} />
          </IconButton>
          <h1>
            <Text variant="heading1">{title}</Text>
          </h1>
        </div>
      ) : (
        <div className="flex flex-row items-center gap-3 px-6 py-[18px]">
          <h1>
            <Text variant="heading1">{title}</Text>
          </h1>
        </div>
      )}
    </header>
  );
}
