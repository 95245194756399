import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@hooks/useApi';
import { Text } from '@ui/Text';
import { Input } from '@ui/Input';
import SearchIcon from '@mui/icons-material/Search';
import { ZonesMap } from './ZonesMap';
import { Table } from '@ui/Table';

export function DataZones() {
  const api = useApi();

  const [search, setSearch] = useState<string>('');
  const [selectedZoneId, setSelectedZoneId] = useState<string | undefined>();

  const zonesQuery = useQuery({
    queryKey: ['zones'],
    queryFn: api.zones.getAllZones,
  });

  let zones = zonesQuery.data?.data.data || [];
  if (search) {
    zones = zones.filter((z) => {
      const searchString = [z.name].join(';').toLowerCase();
      return searchString.includes(search.toLowerCase());
    });
  }

  const selectedZone = zones.find((z) => z.id === selectedZoneId);

  return (
    <div className="grid h-[calc(100vh-114px)] grid-cols-1 grid-rows-2 gap-0 lg:grid-cols-2 lg:grid-rows-1">
      <div className={selectedZone ? 'row-span-1 lg:col-span-1' : 'row-span-2 lg:col-span-2'}>
        <div className="mb-5 h-full border-r-[1px] bg-white shadow-md">
          <div className="flex p-3 sm:p-6">
            <Input
              variant="search"
              LeadingIcon={<SearchIcon className="text-onSurface-highEmphasis" />}
              type="search"
              label="Suche"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="overflow-x-auto overflow-y-auto border-t border-gray-100 pb-16">
            <Table>
              <Table.Header>
                <Table.Row noHover>
                  <Table.Head>Zonen Name</Table.Head>
                  <Table.Head>Aktion</Table.Head>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {zones.map((zone) => {
                  const isSelected = zone.id === selectedZone?.id;

                  return (
                    <Table.Row key={zone.id} selected={isSelected}>
                      <Table.Cell className="align-top">
                        <button
                          onClick={() => setSelectedZoneId(zone.id)}
                          className="text-communication-add hover:underline"
                        >
                          <Text>{zone.name}</Text>
                        </button>
                      </Table.Cell>

                      <Table.Cell>
                        <ul className="list-inside list-decimal space-y-1">
                          {zone.containerEnterAction === 'set_container_filled' && (
                            <li>
                              <Text>
                                Beim Betreten der Zone Container-Status auf{' '}
                                <span className="mx-2 rounded-sm bg-[#CCF1D6] px-4 font-mono">voll</span> setzten
                              </Text>
                            </li>
                          )}

                          {zone.containerEnterAction === 'set_container_empty' && (
                            <li>
                              <Text>
                                Beim Betreten der Zone Container-Status auf{' '}
                                <span className="mx-2 rounded-sm bg-gray-100 px-4 font-mono">leer</span> setzten
                              </Text>
                            </li>
                          )}

                          {zone.containerLeaveAction === 'set_container_filled' && (
                            <li>
                              <Text>
                                Beim Verlassen der Zone Container-Status auf{' '}
                                <span className="mx-2 rounded-sm bg-[#CCF1D6] px-4 font-mono">voll</span> setzten
                              </Text>
                            </li>
                          )}

                          {zone.containerLeaveAction === 'set_container_empty' && (
                            <li>
                              <Text>
                                Beim Verlassen der Zone Container-Status auf{' '}
                                <span className="mx-2 rounded-sm bg-gray-100 px-4 font-mono">leer</span> setzten
                              </Text>
                            </li>
                          )}

                          {!zone.containerEnterAction && !zone.containerLeaveAction && (
                            <Text className="font-mono">-</Text>
                          )}
                        </ul>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>

      {selectedZone && (
        <div className="h-full bg-gray-600">
          <ZonesMap zone={selectedZone} onClose={() => setSelectedZoneId(undefined)} />
        </div>
      )}
    </div>
  );
}
